/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 :root {
	--blue: #2798ea;
	--indigo: #6610f2;
	--purple: #81286D;
	--pink: #e83e8c;
	--red: #FF8A80;
	--orange: #fd7e14;
	--yellow: #efb100;
	--green: #77d600;
	--teal: #20c997;
	--cyan: #33bCc3;
	--white: #fff;
	--gray: #7c858d;
	--gray-dark: #343a40;
	--primary: #2798ea;
	--secondary: #7c858d;
	--success: #77d600;
	--info: #33bCc3;
	--warning: #efb100;
	--danger: #FF8A80;
	--light: #f8f9fa;
	--dark: #343a40;
	--dietbox: #69C600;
	--dietbox-secondary: #5C5091;
	--dietbox-app: #F2B705;
	--pro: #FD0;
	--recebafacil: #5F7D8C;
	--qpc: #d24297;
	--anamnese: #F46B59;
	--antropometria: #9149BD;
	--plano-alimentar: #60B738;
	--plano-alimentar-light: #C9E3DB;
	--recordatorio: #FAAF33;
	--gasto-energetico: #26A6D5;
	--avaliacao-laboratorial: #33797F;
	--prescricao: #384C84;
	--consulta: #2798ea;
	--retorno: #009788;
	--previsao-retorno: #81286D;
	--aniversario: #E2A826;
	--lima: #EFFF33;
	--azul-ceu: #5FC0DD;
	--videoconferencia: #8132b1;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
*::before,
*::after {
	box-sizing: border-box
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

@-ms-viewport {
	width: device-width
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: left;
	background-color: #f8f9fa
}

[tabindex="-1"]:focus {
	outline: 0!important
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: .5rem
}

p {
	margin-top: 0;
	margin-bottom: 1rem
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0
}

dt {
	font-weight: 500
}

dd {
	margin-bottom: .5rem;
	margin-left: 0
}

blockquote {
	margin: 0 0 1rem
}

dfn {
	font-style: italic
}

b,
strong {
	font-weight: bolder
}

small {
	font-size: 80%
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

a {
	color: #2798ea;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects
}

a:hover {
	color: #116fb3;
	text-decoration: underline
}

a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
	color: inherit;
	text-decoration: none
}

a:not([href]):not([tabindex]):focus {
	outline: 0
}

pre,
code,
kbd,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar
}

figure {
	margin: 0 0 1rem
}

img {
	vertical-align: middle;
	border-style: none
}

svg:not(:root) {
	overflow: hidden;
	vertical-align: middle
}

table {
	border-collapse: collapse
}

caption {
	padding-top: .75rem;
	padding-bottom: .75rem;
	color: #adb5bd;
	text-align: left;
	caption-side: bottom
}

th {
	text-align: inherit
}

label {
	display: inline-block;
	margin-bottom: .5rem
}

button {
	border-radius: 0
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button,
input {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox
}

textarea {
	overflow: auto;
	resize: vertical
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal
}

progress {
	vertical-align: baseline
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button
}

output {
	display: inline-block
}

summary {
	display: list-item;
	cursor: pointer
}

template {
	display: none
}

[hidden] {
	display: none!important
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: .5rem;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: inherit
}

h1,
.h1 {
	font-size: 2.5rem
}

h2,
.h2 {
	font-size: 2rem
}

h3,
.h3 {
	font-size: 1.75rem
}

h4,
.h4 {
	font-size: 1.5rem
}

h5,
.h5 {
	font-size: 1.25rem
}

h6,
.h6 {
	font-size: 1rem
}

.lead {
	font-size: 1.25rem;
	font-weight: 300
}

.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1)
}

small,
.small {
	font-size: 80%;
	font-weight: 400
}

mark,
.mark {
	padding: .2em;
	background-color: #fcf8e3
}

.list-unstyled {
	padding-left: 0;
	list-style: none
}

.list-inline {
	padding-left: 0;
	list-style: none
}

.list-inline-item {
	display: inline-block
}

.list-inline-item:not(:last-child) {
	margin-right: .5rem
}

.initialism {
	font-size: 90%;
	text-transform: uppercase
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #7c858d
}

.blockquote-footer::before {
	content: "\2014 \00A0"
}

.img-fluid {
	max-width: 100%;
	height: auto
}

.img-thumbnail {
	padding: .25rem;
	background-color: #f8f9fa;
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	max-width: 100%;
	height: auto
}

.figure {
	display: inline-block
}

.figure-img {
	margin-bottom: .5rem;
	line-height: 1
}

.figure-caption {
	font-size: 90%;
	color: #7c858d
}

code {
	font-size: 87.5%;
	color: #e83e8c;
	word-break: break-word
}

a>code {
	color: inherit
}

kbd {
	padding: .2rem .4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #212529;
	border-radius: .2rem
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 500
}

pre {
	display: block;
	font-size: 87.5%;
	color: #212529
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto
}

@media(min-width:576px) {
	.container {
		max-width: 540px
	}
}

@media(min-width:768px) {
	.container {
		max-width: 720px
	}
}

@media(min-width:992px) {
	.container {
		max-width: 960px
	}
}

@media(min-width:1200px) {
	.container {
		max-width: 1140px
	}
}

.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px
}

.no-gutters {
	margin-right: 0;
	margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
	padding-right: 0;
	padding-left: 0
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: none
}

.col-1 {
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%
}

.col-2 {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%
}

.col-4 {
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%
}

.col-5 {
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%
}

.col-7 {
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%
}

.col-8 {
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%
}

.col-10 {
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%
}

.col-11 {
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%
}

.order-first {
	order: -1
}

.order-last {
	order: 13
}

.order-0 {
	order: 0
}

.order-1 {
	order: 1
}

.order-2 {
	order: 2
}

.order-3 {
	order: 3
}

.order-4 {
	order: 4
}

.order-5 {
	order: 5
}

.order-6 {
	order: 6
}

.order-7 {
	order: 7
}

.order-8 {
	order: 8
}

.order-9 {
	order: 9
}

.order-10 {
	order: 10
}

.order-11 {
	order: 11
}

.order-12 {
	order: 12
}

.offset-1 {
	margin-left: 8.3333333333%
}

.offset-2 {
	margin-left: 16.6666666667%
}

.offset-3 {
	margin-left: 25%
}

.offset-4 {
	margin-left: 33.3333333333%
}

.offset-5 {
	margin-left: 41.6666666667%
}

.offset-6 {
	margin-left: 50%
}

.offset-7 {
	margin-left: 58.3333333333%
}

.offset-8 {
	margin-left: 66.6666666667%
}

.offset-9 {
	margin-left: 75%
}

.offset-10 {
	margin-left: 83.3333333333%
}

.offset-11 {
	margin-left: 91.6666666667%
}

@media(min-width:576px) {
	.col-sm {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-sm-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}
	.col-sm-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}
	.col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-sm-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}
	.col-sm-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}
	.col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-sm-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}
	.col-sm-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}
	.col-sm-9 {
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-sm-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}
	.col-sm-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-sm-first {
		order: -1
	}
	.order-sm-last {
		order: 13
	}
	.order-sm-0 {
		order: 0
	}
	.order-sm-1 {
		order: 1
	}
	.order-sm-2 {
		order: 2
	}
	.order-sm-3 {
		order: 3
	}
	.order-sm-4 {
		order: 4
	}
	.order-sm-5 {
		order: 5
	}
	.order-sm-6 {
		order: 6
	}
	.order-sm-7 {
		order: 7
	}
	.order-sm-8 {
		order: 8
	}
	.order-sm-9 {
		order: 9
	}
	.order-sm-10 {
		order: 10
	}
	.order-sm-11 {
		order: 11
	}
	.order-sm-12 {
		order: 12
	}
	.offset-sm-0 {
		margin-left: 0
	}
	.offset-sm-1 {
		margin-left: 8.3333333333%
	}
	.offset-sm-2 {
		margin-left: 16.6666666667%
	}
	.offset-sm-3 {
		margin-left: 25%
	}
	.offset-sm-4 {
		margin-left: 33.3333333333%
	}
	.offset-sm-5 {
		margin-left: 41.6666666667%
	}
	.offset-sm-6 {
		margin-left: 50%
	}
	.offset-sm-7 {
		margin-left: 58.3333333333%
	}
	.offset-sm-8 {
		margin-left: 66.6666666667%
	}
	.offset-sm-9 {
		margin-left: 75%
	}
	.offset-sm-10 {
		margin-left: 83.3333333333%
	}
	.offset-sm-11 {
		margin-left: 91.6666666667%
	}
}

@media(min-width:768px) {
	.col-md {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-md-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}
	.col-md-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}
	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-md-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}
	.col-md-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-md-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}
	.col-md-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}
	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-md-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}
	.col-md-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}
	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-md-first {
		order: -1
	}
	.order-md-last {
		order: 13
	}
	.order-md-0 {
		order: 0
	}
	.order-md-1 {
		order: 1
	}
	.order-md-2 {
		order: 2
	}
	.order-md-3 {
		order: 3
	}
	.order-md-4 {
		order: 4
	}
	.order-md-5 {
		order: 5
	}
	.order-md-6 {
		order: 6
	}
	.order-md-7 {
		order: 7
	}
	.order-md-8 {
		order: 8
	}
	.order-md-9 {
		order: 9
	}
	.order-md-10 {
		order: 10
	}
	.order-md-11 {
		order: 11
	}
	.order-md-12 {
		order: 12
	}
	.offset-md-0 {
		margin-left: 0
	}
	.offset-md-1 {
		margin-left: 8.3333333333%
	}
	.offset-md-2 {
		margin-left: 16.6666666667%
	}
	.offset-md-3 {
		margin-left: 25%
	}
	.offset-md-4 {
		margin-left: 33.3333333333%
	}
	.offset-md-5 {
		margin-left: 41.6666666667%
	}
	.offset-md-6 {
		margin-left: 50%
	}
	.offset-md-7 {
		margin-left: 58.3333333333%
	}
	.offset-md-8 {
		margin-left: 66.6666666667%
	}
	.offset-md-9 {
		margin-left: 75%
	}
	.offset-md-10 {
		margin-left: 83.3333333333%
	}
	.offset-md-11 {
		margin-left: 91.6666666667%
	}
}

@media(min-width:992px) {
	.col-lg {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-lg-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}
	.col-lg-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}
	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-lg-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}
	.col-lg-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-lg-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}
	.col-lg-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}
	.col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-lg-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}
	.col-lg-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-lg-first {
		order: -1
	}
	.order-lg-last {
		order: 13
	}
	.order-lg-0 {
		order: 0
	}
	.order-lg-1 {
		order: 1
	}
	.order-lg-2 {
		order: 2
	}
	.order-lg-3 {
		order: 3
	}
	.order-lg-4 {
		order: 4
	}
	.order-lg-5 {
		order: 5
	}
	.order-lg-6 {
		order: 6
	}
	.order-lg-7 {
		order: 7
	}
	.order-lg-8 {
		order: 8
	}
	.order-lg-9 {
		order: 9
	}
	.order-lg-10 {
		order: 10
	}
	.order-lg-11 {
		order: 11
	}
	.order-lg-12 {
		order: 12
	}
	.offset-lg-0 {
		margin-left: 0
	}
	.offset-lg-1 {
		margin-left: 8.3333333333%
	}
	.offset-lg-2 {
		margin-left: 16.6666666667%
	}
	.offset-lg-3 {
		margin-left: 25%
	}
	.offset-lg-4 {
		margin-left: 33.3333333333%
	}
	.offset-lg-5 {
		margin-left: 41.6666666667%
	}
	.offset-lg-6 {
		margin-left: 50%
	}
	.offset-lg-7 {
		margin-left: 58.3333333333%
	}
	.offset-lg-8 {
		margin-left: 66.6666666667%
	}
	.offset-lg-9 {
		margin-left: 75%
	}
	.offset-lg-10 {
		margin-left: 83.3333333333%
	}
	.offset-lg-11 {
		margin-left: 91.6666666667%
	}
}

@media(min-width:1200px) {
	.col-xl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: none
	}
	.col-xl-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%
	}
	.col-xl-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%
	}
	.col-xl-3 {
		flex: 0 0 25%;
		max-width: 25%
	}
	.col-xl-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%
	}
	.col-xl-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%
	}
	.col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%
	}
	.col-xl-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%
	}
	.col-xl-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%
	}
	.col-xl-9 {
		flex: 0 0 75%;
		max-width: 75%
	}
	.col-xl-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%
	}
	.col-xl-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%
	}
	.col-xl-12 {
		flex: 0 0 100%;
		max-width: 100%
	}
	.order-xl-first {
		order: -1
	}
	.order-xl-last {
		order: 13
	}
	.order-xl-0 {
		order: 0
	}
	.order-xl-1 {
		order: 1
	}
	.order-xl-2 {
		order: 2
	}
	.order-xl-3 {
		order: 3
	}
	.order-xl-4 {
		order: 4
	}
	.order-xl-5 {
		order: 5
	}
	.order-xl-6 {
		order: 6
	}
	.order-xl-7 {
		order: 7
	}
	.order-xl-8 {
		order: 8
	}
	.order-xl-9 {
		order: 9
	}
	.order-xl-10 {
		order: 10
	}
	.order-xl-11 {
		order: 11
	}
	.order-xl-12 {
		order: 12
	}
	.offset-xl-0 {
		margin-left: 0
	}
	.offset-xl-1 {
		margin-left: 8.3333333333%
	}
	.offset-xl-2 {
		margin-left: 16.6666666667%
	}
	.offset-xl-3 {
		margin-left: 25%
	}
	.offset-xl-4 {
		margin-left: 33.3333333333%
	}
	.offset-xl-5 {
		margin-left: 41.6666666667%
	}
	.offset-xl-6 {
		margin-left: 50%
	}
	.offset-xl-7 {
		margin-left: 58.3333333333%
	}
	.offset-xl-8 {
		margin-left: 66.6666666667%
	}
	.offset-xl-9 {
		margin-left: 75%
	}
	.offset-xl-10 {
		margin-left: 83.3333333333%
	}
	.offset-xl-11 {
		margin-left: 91.6666666667%
	}
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent
}

.table th,
.table td {
	padding: .75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
	border-top: 2px solid #dee2e6
}

.table .table {
	background-color: #f8f9fa
}

.table-sm th,
.table-sm td {
	padding: .3rem
}

.table-bordered {
	border: 1px solid #dee2e6
}

.table-bordered th,
.table-bordered td {
	border: 1px solid #dee2e6
}

.table-bordered thead th,
.table-bordered thead td {
	border-bottom-width: 2px
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
	border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05)
}

.table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075)
}

.table-primary,
.table-primary>th,
.table-primary>td {
	background-color: #c3e2f9
}

.table-hover .table-primary:hover {
	background-color: #acd7f7
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
	background-color: #acd7f7
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
	background-color: #dadddf
}

.table-hover .table-secondary:hover {
	background-color: #ccd0d3
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
	background-color: #ccd0d3
}

.table-success,
.table-success>th,
.table-success>td {
	background-color: #d9f4b8
}

.table-hover .table-success:hover {
	background-color: #cdf1a2
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
	background-color: #cdf1a2
}

.table-info,
.table-info>th,
.table-info>td {
	background-color: #c6ecee
}

.table-hover .table-info:hover {
	background-color: #b2e5e8
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
	background-color: #b2e5e8
}

.table-warning,
.table-warning>th,
.table-warning>td {
	background-color: #fbe9b8
}

.table-hover .table-warning:hover {
	background-color: #fae2a0
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
	background-color: #fae2a0
}

.table-danger,
.table-danger>th,
.table-danger>td {
	background-color: #ffdedb
}

.table-hover .table-danger:hover {
	background-color: #ffc7c2
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
	background-color: #ffc7c2
}

.table-light,
.table-light>th,
.table-light>td {
	background-color: #fdfdfe
}

.table-hover .table-light:hover {
	background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
	background-color: #ececf6
}

.table-dark,
.table-dark>th,
.table-dark>td {
	background-color: #c6c8ca
}

.table-hover .table-dark:hover {
	background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
	background-color: #b9bbbe
}

.table-dietbox,
.table-dietbox>th,
.table-dietbox>td {
	background-color: #d5efb8
}

.table-hover .table-dietbox:hover {
	background-color: #c9eaa3
}

.table-hover .table-dietbox:hover>td,
.table-hover .table-dietbox:hover>th {
	background-color: #c9eaa3
}

.table-dietbox-secondary,
.table-dietbox-secondary>th,
.table-dietbox-secondary>td {
	background-color: #d1cee0
}

.table-hover .table-dietbox-secondary:hover {
	background-color: #c2bed6
}

.table-hover .table-dietbox-secondary:hover>td,
.table-hover .table-dietbox-secondary:hover>th {
	background-color: #c2bed6
}

.table-dietbox-app,
.table-dietbox-app>th,
.table-dietbox-app>td {
	background-color: #fbebb9
}

.table-hover .table-dietbox-app:hover {
	background-color: #fae4a1
}

.table-hover .table-dietbox-app:hover>td,
.table-hover .table-dietbox-app:hover>th {
	background-color: #fae4a1
}

.table-pro,
.table-pro>th,
.table-pro>td {
	background-color: #fff5b8
}

.table-hover .table-pro:hover {
	background-color: #fff19f
}

.table-hover .table-pro:hover>td,
.table-hover .table-pro:hover>th {
	background-color: #fff19f
}

.table-recebafacil,
.table-recebafacil>th,
.table-recebafacil>td {
	background-color: #d2dbdf
}

.table-hover .table-recebafacil:hover {
	background-color: #c3cfd4
}

.table-hover .table-recebafacil:hover>td,
.table-hover .table-recebafacil:hover>th {
	background-color: #c3cfd4
}

.table-qpc,
.table-qpc>th,
.table-qpc>td {
	background-color: #f2cae2
}

.table-hover .table-qpc:hover {
	background-color: #edb6d7
}

.table-hover .table-qpc:hover>td,
.table-hover .table-qpc:hover>th {
	background-color: #edb6d7
}

.table-anamnese,
.table-anamnese>th,
.table-anamnese>td {
	background-color: #fcd6d1
}

.table-hover .table-anamnese:hover {
	background-color: #fac1b9
}

.table-hover .table-anamnese:hover>td,
.table-hover .table-anamnese:hover>th {
	background-color: #fac1b9
}

.table-antropometria,
.table-antropometria>th,
.table-antropometria>td {
	background-color: #e0cced
}

.table-hover .table-antropometria:hover {
	background-color: #d5b9e6
}

.table-hover .table-antropometria:hover>td,
.table-hover .table-antropometria:hover>th {
	background-color: #d5b9e6
}

.table-plano-alimentar,
.table-plano-alimentar>th,
.table-plano-alimentar>td {
	background-color: #d2ebc7
}

.table-hover .table-plano-alimentar:hover {
	background-color: #c3e4b4
}

.table-hover .table-plano-alimentar:hover>td,
.table-hover .table-plano-alimentar:hover>th {
	background-color: #c3e4b4
}

.table-plano-alimentar-light,
.table-plano-alimentar-light>th,
.table-plano-alimentar-light>td {
	background-color: #f0f7f5
}

.table-hover .table-plano-alimentar-light:hover {
	background-color: #dfeeea
}

.table-hover .table-plano-alimentar-light:hover>td,
.table-hover .table-plano-alimentar-light:hover>th {
	background-color: #dfeeea
}

.table-recordatorio,
.table-recordatorio>th,
.table-recordatorio>td {
	background-color: #fee9c6
}

.table-hover .table-recordatorio:hover {
	background-color: #fedfad
}

.table-hover .table-recordatorio:hover>td,
.table-hover .table-recordatorio:hover>th {
	background-color: #fedfad
}

.table-gasto-energetico,
.table-gasto-energetico>th,
.table-gasto-energetico>td {
	background-color: #c2e6f3
}

.table-hover .table-gasto-energetico:hover {
	background-color: #adddef
}

.table-hover .table-gasto-energetico:hover>td,
.table-hover .table-gasto-energetico:hover>th {
	background-color: #adddef
}

.table-avaliacao-laboratorial,
.table-avaliacao-laboratorial>th,
.table-avaliacao-laboratorial>td {
	background-color: #c6d9db
}

.table-hover .table-avaliacao-laboratorial:hover {
	background-color: #b6cfd1
}

.table-hover .table-avaliacao-laboratorial:hover>td,
.table-hover .table-avaliacao-laboratorial:hover>th {
	background-color: #b6cfd1
}

.table-prescricao,
.table-prescricao>th,
.table-prescricao>td {
	background-color: #c7cddd
}

.table-hover .table-prescricao:hover {
	background-color: #b7bfd3
}

.table-hover .table-prescricao:hover>td,
.table-hover .table-prescricao:hover>th {
	background-color: #b7bfd3
}

.table-consulta,
.table-consulta>th,
.table-consulta>td {
	background-color: #c3e2f9
}

.table-hover .table-consulta:hover {
	background-color: #acd7f7
}

.table-hover .table-consulta:hover>td,
.table-hover .table-consulta:hover>th {
	background-color: #acd7f7
}

.table-retorno,
.table-retorno>th,
.table-retorno>td {
	background-color: #b8e2de
}

.table-hover .table-retorno:hover {
	background-color: #a6dbd6
}

.table-hover .table-retorno:hover>td,
.table-hover .table-retorno:hover>th {
	background-color: #a6dbd6
}

.table-previsao-retorno,
.table-previsao-retorno>th,
.table-previsao-retorno>td {
	background-color: #dcc3d6
}

.table-hover .table-previsao-retorno:hover {
	background-color: #d3b3cb
}

.table-hover .table-previsao-retorno:hover>td,
.table-hover .table-previsao-retorno:hover>th {
	background-color: #d3b3cb
}

.table-aniversario,
.table-aniversario>th,
.table-aniversario>td {
	background-color: #f7e7c2
}

.table-hover .table-aniversario:hover {
	background-color: #f4deab
}

.table-hover .table-aniversario:hover>td,
.table-hover .table-aniversario:hover>th {
	background-color: #f4deab
}

.table-lima,
.table-lima>th,
.table-lima>td {
	background-color: #fbffc6
}

.table-hover .table-lima:hover {
	background-color: #f9ffad
}

.table-hover .table-lima:hover>td,
.table-hover .table-lima:hover>th {
	background-color: #f9ffad
}

.table-azul-ceu,
.table-azul-ceu>th,
.table-azul-ceu>td {
	background-color: #d2edf5
}

.table-hover .table-azul-ceu:hover {
	background-color: #bde5f0
}

.table-hover .table-azul-ceu:hover>td,
.table-hover .table-azul-ceu:hover>th {
	background-color: #bde5f0
}

.table-videoconferencia,
.table-videoconferencia>th,
.table-videoconferencia>td {
	background-color: #dcc6e9
}

.table-hover .table-videoconferencia:hover {
	background-color: #d1b4e2
}

.table-hover .table-videoconferencia:hover>td,
.table-hover .table-videoconferencia:hover>th {
	background-color: #d1b4e2
}

.table-active,
.table-active>th,
.table-active>td {
	background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
	background-color: rgba(0, 0, 0, 0.075)
}

.table .thead-dark th {
	color: #f8f9fa;
	background-color: #212529;
	border-color: #32383e
}

.table .thead-light th {
	color: #495057;
	background-color: #e9ecef;
	border-color: #dee2e6
}

.table-dark {
	color: #f8f9fa;
	background-color: #212529
}

.table-dark th,
.table-dark td,
.table-dark thead th {
	border-color: #32383e
}

.table-dark.table-bordered {
	border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, 0.05)
}

.table-dark.table-hover tbody tr:hover {
	background-color: rgba(255, 255, 255, 0.075)
}

@media(max-width:575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-sm>.table-bordered {
		border: 0
	}
}

@media(max-width:767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-md>.table-bordered {
		border: 0
	}
}

@media(max-width:991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-lg>.table-bordered {
		border: 0
	}
}

@media(max-width:1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar
	}
	.table-responsive-xl>.table-bordered {
		border: 0
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive>.table-bordered {
	border: 0
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion:reduce) {
	.form-control {
		transition: none
	}
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #9bcff5;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.form-control::placeholder {
	color: #7c858d;
	opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1
}

select.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 2px)
}

select.form-control:focus::-ms-value {
	color: #495057;
	background-color: #fff
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.25rem;
	line-height: 1.5
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: .875rem;
	line-height: 1.5
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin-bottom: 0;
	line-height: 1.5;
	color: #495057;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0
}

.form-control-plaintext.form-control-sm,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.btn {
	padding-right: 0;
	padding-left: 0
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
	height: calc(1.8125rem + 2px)
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
	padding: .5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: .3rem
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
	height: calc(2.875rem + 2px)
}

.form-group {
	margin-bottom: 1rem
}

.form-text {
	display: block;
	margin-top: .25rem
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px
}

.form-row>.col,
.form-row>[class*="col-"] {
	padding-right: 5px;
	padding-left: 5px
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem
}

.form-check-input {
	position: absolute;
	margin-top: .3rem;
	margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
	color: #adb5bd
}

.form-check-label {
	margin-bottom: 0
}

.form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	margin-right: .75rem
}

.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: .3125rem;
	margin-left: 0
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #77d600
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(119, 214, 0, 0.8);
	border-radius: .2rem
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #77d600
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
	border-color: #77d600;
	box-shadow: 0 0 0 .2rem rgba(119, 214, 0, 0.25)
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
	display: block
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip {
	display: block
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
	color: #77d600
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
	display: block
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
	color: #77d600
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
	background-color: #b4ff57
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
	display: block
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
	background-color: #92ff0a
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 .2rem rgba(119, 214, 0, 0.25)
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
	border-color: #77d600
}

.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
	border-color: inherit
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
	display: block
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
	box-shadow: 0 0 0 .2rem rgba(119, 214, 0, 0.25)
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #FF8A80
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(255, 138, 128, 0.8);
	border-radius: .2rem
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #FF8A80
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	border-color: #FF8A80;
	box-shadow: 0 0 0 .2rem rgba(255, 138, 128, 0.25)
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
	color: #FF8A80
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
	color: #FF8A80
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
	background-color: white
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
	background-color: #ffb9b3
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 .2rem rgba(255, 138, 128, 0.25)
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
	border-color: #FF8A80
}

.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
	border-color: inherit
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
	box-shadow: 0 0 0 .2rem rgba(255, 138, 128, 0.25)
}

.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center
}

.form-inline .form-check {
	width: 100%
}

@media(min-width:576px) {
	.form-inline label {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0
	}
	.form-inline .form-group {
		display: flex;
		flex: 0 0 auto;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 0
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle
	}
	.form-inline .form-control-plaintext {
		display: inline-block
	}
	.form-inline .input-group,
	.form-inline .custom-select {
		width: auto
	}
	.form-inline .form-check {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding-left: 0
	}
	.form-inline .form-check-input {
		position: relative;
		margin-top: 0;
		margin-right: .25rem;
		margin-left: 0
	}
	.form-inline .custom-control {
		align-items: center;
		justify-content: center
	}
	.form-inline .custom-control-label {
		margin-bottom: 0
	}
}

.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: .25rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion:reduce) {
	.btn {
		transition: none
	}
}

.btn:hover,
.btn:focus {
	text-decoration: none
}

.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.btn.disabled,
.btn:disabled {
	opacity: .65
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
	background-image: none
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none
}

.btn-primary {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-primary:hover {
	color: #fff;
	background-color: #1585d6;
	border-color: #147eca
}

.btn-primary:focus,
.btn-primary.focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #147eca;
	border-color: #1376bf
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-secondary {
	color: #fff;
	background-color: #7c858d;
	border-color: #7c858d
}

.btn-secondary:hover {
	color: #fff;
	background-color: #6a7279;
	border-color: #646b72
}

.btn-secondary:focus,
.btn-secondary.focus {
	box-shadow: 0 0 0 .2rem rgba(124, 133, 141, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #fff;
	background-color: #7c858d;
	border-color: #7c858d
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #646b72;
	border-color: #5e656c
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(124, 133, 141, 0.5)
}

.btn-success {
	color: #fff;
	background-color: #77d600;
	border-color: #77d600
}

.btn-success:hover {
	color: #fff;
	background-color: #62b000;
	border-color: #5ba300
}

.btn-success:focus,
.btn-success.focus {
	box-shadow: 0 0 0 .2rem rgba(119, 214, 0, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
	color: #fff;
	background-color: #77d600;
	border-color: #77d600
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: #5ba300;
	border-color: #549600
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(119, 214, 0, 0.5)
}

.btn-info {
	color: #fff;
	background-color: #33bCc3;
	border-color: #33bCc3
}

.btn-info:hover {
	color: #fff;
	background-color: #2b9fa5;
	border-color: #28959b
}

.btn-info:focus,
.btn-info.focus {
	box-shadow: 0 0 0 .2rem rgba(51, 188, 195, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
	color: #fff;
	background-color: #33bCc3;
	border-color: #33bCc3
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
	color: #fff;
	background-color: #28959b;
	border-color: #268b90
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(51, 188, 195, 0.5)
}

.btn-warning {
	color: #fff;
	background-color: #efb100;
	border-color: #efb100
}

.btn-warning:hover {
	color: #fff;
	background-color: #c99500;
	border-color: #bc8b00
}

.btn-warning:focus,
.btn-warning.focus {
	box-shadow: 0 0 0 .2rem rgba(239, 177, 0, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
	color: #fff;
	background-color: #efb100;
	border-color: #efb100
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
	color: #fff;
	background-color: #bc8b00;
	border-color: #af8200
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(239, 177, 0, 0.5)
}

.btn-danger {
	color: #fff;
	background-color: #FF8A80;
	border-color: #FF8A80
}

.btn-danger:hover {
	color: #fff;
	background-color: #ff675a;
	border-color: #ff5b4d
}

.btn-danger:focus,
.btn-danger.focus {
	box-shadow: 0 0 0 .2rem rgba(255, 138, 128, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
	color: #fff;
	background-color: #FF8A80;
	border-color: #FF8A80
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #ff5b4d;
	border-color: #ff4f40
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 138, 128, 0.5)
}

.btn-light {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-light:hover {
	color: #212529;
	background-color: #e2e6ea;
	border-color: #dae0e5
}

.btn-light:focus,
.btn-light.focus {
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-light.disabled,
.btn-light:disabled {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
	color: #212529;
	background-color: #dae0e5;
	border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-dark:hover {
	color: #fff;
	background-color: #23272b;
	border-color: #1d2124
}

.btn-dark:focus,
.btn-dark.focus {
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-dark.disabled,
.btn-dark:disabled {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #1d2124;
	border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-dietbox {
	color: #fff;
	background-color: #69C600;
	border-color: #69C600
}

.btn-dietbox:hover {
	color: #fff;
	background-color: #55a000;
	border-color: #4e9300
}

.btn-dietbox:focus,
.btn-dietbox.focus {
	box-shadow: 0 0 0 .2rem rgba(105, 198, 0, 0.5)
}

.btn-dietbox.disabled,
.btn-dietbox:disabled {
	color: #fff;
	background-color: #69C600;
	border-color: #69C600
}

.btn-dietbox:not(:disabled):not(.disabled):active,
.btn-dietbox:not(:disabled):not(.disabled).active,
.show>.btn-dietbox.dropdown-toggle {
	color: #fff;
	background-color: #4e9300;
	border-color: #478600
}

.btn-dietbox:not(:disabled):not(.disabled):active:focus,
.btn-dietbox:not(:disabled):not(.disabled).active:focus,
.show>.btn-dietbox.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(105, 198, 0, 0.5)
}

.btn-dietbox-secondary {
	color: #fff;
	background-color: #5C5091;
	border-color: #5C5091
}

.btn-dietbox-secondary:hover {
	color: #fff;
	background-color: #4c4278;
	border-color: #473e70
}

.btn-dietbox-secondary:focus,
.btn-dietbox-secondary.focus {
	box-shadow: 0 0 0 .2rem rgba(92, 80, 145, 0.5)
}

.btn-dietbox-secondary.disabled,
.btn-dietbox-secondary:disabled {
	color: #fff;
	background-color: #5C5091;
	border-color: #5C5091
}

.btn-dietbox-secondary:not(:disabled):not(.disabled):active,
.btn-dietbox-secondary:not(:disabled):not(.disabled).active,
.show>.btn-dietbox-secondary.dropdown-toggle {
	color: #fff;
	background-color: #473e70;
	border-color: #423968
}

.btn-dietbox-secondary:not(:disabled):not(.disabled):active:focus,
.btn-dietbox-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-dietbox-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(92, 80, 145, 0.5)
}

.btn-dietbox-app {
	color: #212529;
	background-color: #F2B705;
	border-color: #F2B705
}

.btn-dietbox-app:hover {
	color: #fff;
	background-color: #cd9b04;
	border-color: #c09104
}

.btn-dietbox-app:focus,
.btn-dietbox-app.focus {
	box-shadow: 0 0 0 .2rem rgba(242, 183, 5, 0.5)
}

.btn-dietbox-app.disabled,
.btn-dietbox-app:disabled {
	color: #212529;
	background-color: #F2B705;
	border-color: #F2B705
}

.btn-dietbox-app:not(:disabled):not(.disabled):active,
.btn-dietbox-app:not(:disabled):not(.disabled).active,
.show>.btn-dietbox-app.dropdown-toggle {
	color: #fff;
	background-color: #c09104;
	border-color: #b48804
}

.btn-dietbox-app:not(:disabled):not(.disabled):active:focus,
.btn-dietbox-app:not(:disabled):not(.disabled).active:focus,
.show>.btn-dietbox-app.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(242, 183, 5, 0.5)
}

.btn-pro {
	color: #212529;
	background-color: #FD0;
	border-color: #FD0
}

.btn-pro:hover {
	color: #fff;
	background-color: #d9bc00;
	border-color: #ccb100
}

.btn-pro:focus,
.btn-pro.focus {
	box-shadow: 0 0 0 .2rem rgba(255, 221, 0, 0.5)
}

.btn-pro.disabled,
.btn-pro:disabled {
	color: #212529;
	background-color: #FD0;
	border-color: #FD0
}

.btn-pro:not(:disabled):not(.disabled):active,
.btn-pro:not(:disabled):not(.disabled).active,
.show>.btn-pro.dropdown-toggle {
	color: #fff;
	background-color: #ccb100;
	border-color: #bfa600
}

.btn-pro:not(:disabled):not(.disabled):active:focus,
.btn-pro:not(:disabled):not(.disabled).active:focus,
.show>.btn-pro.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 221, 0, 0.5)
}

.btn-recebafacil {
	color: #fff;
	background-color: #5F7D8C;
	border-color: #5F7D8C
}

.btn-recebafacil:hover {
	color: #fff;
	background-color: #506975;
	border-color: #4a626e
}

.btn-recebafacil:focus,
.btn-recebafacil.focus {
	box-shadow: 0 0 0 .2rem rgba(95, 125, 140, 0.5)
}

.btn-recebafacil.disabled,
.btn-recebafacil:disabled {
	color: #fff;
	background-color: #5F7D8C;
	border-color: #5F7D8C
}

.btn-recebafacil:not(:disabled):not(.disabled):active,
.btn-recebafacil:not(:disabled):not(.disabled).active,
.show>.btn-recebafacil.dropdown-toggle {
	color: #fff;
	background-color: #4a626e;
	border-color: #455b66
}

.btn-recebafacil:not(:disabled):not(.disabled):active:focus,
.btn-recebafacil:not(:disabled):not(.disabled).active:focus,
.show>.btn-recebafacil.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(95, 125, 140, 0.5)
}

.btn-qpc {
	color: #fff;
	background-color: #d24297;
	border-color: #d24297
}

.btn-qpc:hover {
	color: #fff;
	background-color: #c02e84;
	border-color: #b62b7d
}

.btn-qpc:focus,
.btn-qpc.focus {
	box-shadow: 0 0 0 .2rem rgba(210, 66, 151, 0.5)
}

.btn-qpc.disabled,
.btn-qpc:disabled {
	color: #fff;
	background-color: #d24297;
	border-color: #d24297
}

.btn-qpc:not(:disabled):not(.disabled):active,
.btn-qpc:not(:disabled):not(.disabled).active,
.show>.btn-qpc.dropdown-toggle {
	color: #fff;
	background-color: #b62b7d;
	border-color: #ab2976
}

.btn-qpc:not(:disabled):not(.disabled):active:focus,
.btn-qpc:not(:disabled):not(.disabled).active:focus,
.show>.btn-qpc.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(210, 66, 151, 0.5)
}

.btn-anamnese {
	color: #fff;
	background-color: #F46B59;
	border-color: #F46B59
}

.btn-anamnese:hover {
	color: #fff;
	background-color: #f24b35;
	border-color: #f14029
}

.btn-anamnese:focus,
.btn-anamnese.focus {
	box-shadow: 0 0 0 .2rem rgba(244, 107, 89, 0.5)
}

.btn-anamnese.disabled,
.btn-anamnese:disabled {
	color: #fff;
	background-color: #F46B59;
	border-color: #F46B59
}

.btn-anamnese:not(:disabled):not(.disabled):active,
.btn-anamnese:not(:disabled):not(.disabled).active,
.show>.btn-anamnese.dropdown-toggle {
	color: #fff;
	background-color: #f14029;
	border-color: #f0361d
}

.btn-anamnese:not(:disabled):not(.disabled):active:focus,
.btn-anamnese:not(:disabled):not(.disabled).active:focus,
.show>.btn-anamnese.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(244, 107, 89, 0.5)
}

.btn-antropometria {
	color: #fff;
	background-color: #9149BD;
	border-color: #9149BD
}

.btn-antropometria:hover {
	color: #fff;
	background-color: #7d3ca4;
	border-color: #75389b
}

.btn-antropometria:focus,
.btn-antropometria.focus {
	box-shadow: 0 0 0 .2rem rgba(145, 73, 189, 0.5)
}

.btn-antropometria.disabled,
.btn-antropometria:disabled {
	color: #fff;
	background-color: #9149BD;
	border-color: #9149BD
}

.btn-antropometria:not(:disabled):not(.disabled):active,
.btn-antropometria:not(:disabled):not(.disabled).active,
.show>.btn-antropometria.dropdown-toggle {
	color: #fff;
	background-color: #75389b;
	border-color: #6e3591
}

.btn-antropometria:not(:disabled):not(.disabled):active:focus,
.btn-antropometria:not(:disabled):not(.disabled).active:focus,
.show>.btn-antropometria.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(145, 73, 189, 0.5)
}

.btn-plano-alimentar {
	color: #fff;
	background-color: #60B738;
	border-color: #60B738
}

.btn-plano-alimentar:hover {
	color: #fff;
	background-color: #519a2f;
	border-color: #4c902c
}

.btn-plano-alimentar:focus,
.btn-plano-alimentar.focus {
	box-shadow: 0 0 0 .2rem rgba(96, 183, 56, 0.5)
}

.btn-plano-alimentar.disabled,
.btn-plano-alimentar:disabled {
	color: #fff;
	background-color: #60B738;
	border-color: #60B738
}

.btn-plano-alimentar:not(:disabled):not(.disabled):active,
.btn-plano-alimentar:not(:disabled):not(.disabled).active,
.show>.btn-plano-alimentar.dropdown-toggle {
	color: #fff;
	background-color: #4c902c;
	border-color: #468629
}

.btn-plano-alimentar:not(:disabled):not(.disabled):active:focus,
.btn-plano-alimentar:not(:disabled):not(.disabled).active:focus,
.show>.btn-plano-alimentar.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(96, 183, 56, 0.5)
}

.btn-plano-alimentar-light {
	color: #212529;
	background-color: #C9E3DB;
	border-color: #C9E3DB
}

.btn-plano-alimentar-light:hover {
	color: #212529;
	background-color: #b0d6ca;
	border-color: #a7d2c5
}

.btn-plano-alimentar-light:focus,
.btn-plano-alimentar-light.focus {
	box-shadow: 0 0 0 .2rem rgba(201, 227, 219, 0.5)
}

.btn-plano-alimentar-light.disabled,
.btn-plano-alimentar-light:disabled {
	color: #212529;
	background-color: #C9E3DB;
	border-color: #C9E3DB
}

.btn-plano-alimentar-light:not(:disabled):not(.disabled):active,
.btn-plano-alimentar-light:not(:disabled):not(.disabled).active,
.show>.btn-plano-alimentar-light.dropdown-toggle {
	color: #212529;
	background-color: #a7d2c5;
	border-color: #9fcdbf
}

.btn-plano-alimentar-light:not(:disabled):not(.disabled):active:focus,
.btn-plano-alimentar-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-plano-alimentar-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(201, 227, 219, 0.5)
}

.btn-recordatorio {
	color: #212529;
	background-color: #FAAF33;
	border-color: #FAAF33
}

.btn-recordatorio:hover {
	color: #fff;
	background-color: #f9a00e;
	border-color: #f49a06
}

.btn-recordatorio:focus,
.btn-recordatorio.focus {
	box-shadow: 0 0 0 .2rem rgba(250, 175, 51, 0.5)
}

.btn-recordatorio.disabled,
.btn-recordatorio:disabled {
	color: #212529;
	background-color: #FAAF33;
	border-color: #FAAF33
}

.btn-recordatorio:not(:disabled):not(.disabled):active,
.btn-recordatorio:not(:disabled):not(.disabled).active,
.show>.btn-recordatorio.dropdown-toggle {
	color: #fff;
	background-color: #f49a06;
	border-color: #e89206
}

.btn-recordatorio:not(:disabled):not(.disabled):active:focus,
.btn-recordatorio:not(:disabled):not(.disabled).active:focus,
.show>.btn-recordatorio.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(250, 175, 51, 0.5)
}

.btn-gasto-energetico {
	color: #fff;
	background-color: #26A6D5;
	border-color: #26A6D5
}

.btn-gasto-energetico:hover {
	color: #fff;
	background-color: #208db5;
	border-color: #1e84aa
}

.btn-gasto-energetico:focus,
.btn-gasto-energetico.focus {
	box-shadow: 0 0 0 .2rem rgba(38, 166, 213, 0.5)
}

.btn-gasto-energetico.disabled,
.btn-gasto-energetico:disabled {
	color: #fff;
	background-color: #26A6D5;
	border-color: #26A6D5
}

.btn-gasto-energetico:not(:disabled):not(.disabled):active,
.btn-gasto-energetico:not(:disabled):not(.disabled).active,
.show>.btn-gasto-energetico.dropdown-toggle {
	color: #fff;
	background-color: #1e84aa;
	border-color: #1c7c9f
}

.btn-gasto-energetico:not(:disabled):not(.disabled):active:focus,
.btn-gasto-energetico:not(:disabled):not(.disabled).active:focus,
.show>.btn-gasto-energetico.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(38, 166, 213, 0.5)
}

.btn-avaliacao-laboratorial {
	color: #fff;
	background-color: #33797F;
	border-color: #33797F
}

.btn-avaliacao-laboratorial:hover {
	color: #fff;
	background-color: #285f64;
	border-color: #24565b
}

.btn-avaliacao-laboratorial:focus,
.btn-avaliacao-laboratorial.focus {
	box-shadow: 0 0 0 .2rem rgba(51, 121, 127, 0.5)
}

.btn-avaliacao-laboratorial.disabled,
.btn-avaliacao-laboratorial:disabled {
	color: #fff;
	background-color: #33797F;
	border-color: #33797F
}

.btn-avaliacao-laboratorial:not(:disabled):not(.disabled):active,
.btn-avaliacao-laboratorial:not(:disabled):not(.disabled).active,
.show>.btn-avaliacao-laboratorial.dropdown-toggle {
	color: #fff;
	background-color: #24565b;
	border-color: #214e52
}

.btn-avaliacao-laboratorial:not(:disabled):not(.disabled):active:focus,
.btn-avaliacao-laboratorial:not(:disabled):not(.disabled).active:focus,
.show>.btn-avaliacao-laboratorial.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(51, 121, 127, 0.5)
}

.btn-prescricao {
	color: #fff;
	background-color: #384C84;
	border-color: #384C84
}

.btn-prescricao:hover {
	color: #fff;
	background-color: #2d3d69;
	border-color: #293760
}

.btn-prescricao:focus,
.btn-prescricao.focus {
	box-shadow: 0 0 0 .2rem rgba(56, 76, 132, 0.5)
}

.btn-prescricao.disabled,
.btn-prescricao:disabled {
	color: #fff;
	background-color: #384C84;
	border-color: #384C84
}

.btn-prescricao:not(:disabled):not(.disabled):active,
.btn-prescricao:not(:disabled):not(.disabled).active,
.show>.btn-prescricao.dropdown-toggle {
	color: #fff;
	background-color: #293760;
	border-color: #253257
}

.btn-prescricao:not(:disabled):not(.disabled):active:focus,
.btn-prescricao:not(:disabled):not(.disabled).active:focus,
.show>.btn-prescricao.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(56, 76, 132, 0.5)
}

.btn-consulta {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-consulta:hover {
	color: #fff;
	background-color: #1585d6;
	border-color: #147eca
}

.btn-consulta:focus,
.btn-consulta.focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-consulta.disabled,
.btn-consulta:disabled {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-consulta:not(:disabled):not(.disabled):active,
.btn-consulta:not(:disabled):not(.disabled).active,
.show>.btn-consulta.dropdown-toggle {
	color: #fff;
	background-color: #147eca;
	border-color: #1376bf
}

.btn-consulta:not(:disabled):not(.disabled):active:focus,
.btn-consulta:not(:disabled):not(.disabled).active:focus,
.show>.btn-consulta.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-retorno {
	color: #fff;
	background-color: #009788;
	border-color: #009788
}

.btn-retorno:hover {
	color: #fff;
	background-color: #007166;
	border-color: #00645a
}

.btn-retorno:focus,
.btn-retorno.focus {
	box-shadow: 0 0 0 .2rem rgba(0, 151, 136, 0.5)
}

.btn-retorno.disabled,
.btn-retorno:disabled {
	color: #fff;
	background-color: #009788;
	border-color: #009788
}

.btn-retorno:not(:disabled):not(.disabled):active,
.btn-retorno:not(:disabled):not(.disabled).active,
.show>.btn-retorno.dropdown-toggle {
	color: #fff;
	background-color: #00645a;
	border-color: #00574f
}

.btn-retorno:not(:disabled):not(.disabled):active:focus,
.btn-retorno:not(:disabled):not(.disabled).active:focus,
.show>.btn-retorno.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(0, 151, 136, 0.5)
}

.btn-previsao-retorno {
	color: #fff;
	background-color: #81286D;
	border-color: #81286D
}

.btn-previsao-retorno:hover {
	color: #fff;
	background-color: #641f54;
	border-color: #5a1c4c
}

.btn-previsao-retorno:focus,
.btn-previsao-retorno.focus {
	box-shadow: 0 0 0 .2rem rgba(129, 40, 109, 0.5)
}

.btn-previsao-retorno.disabled,
.btn-previsao-retorno:disabled {
	color: #fff;
	background-color: #81286D;
	border-color: #81286D
}

.btn-previsao-retorno:not(:disabled):not(.disabled):active,
.btn-previsao-retorno:not(:disabled):not(.disabled).active,
.show>.btn-previsao-retorno.dropdown-toggle {
	color: #fff;
	background-color: #5a1c4c;
	border-color: #501944
}

.btn-previsao-retorno:not(:disabled):not(.disabled):active:focus,
.btn-previsao-retorno:not(:disabled):not(.disabled).active:focus,
.show>.btn-previsao-retorno.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(129, 40, 109, 0.5)
}

.btn-aniversario {
	color: #fff;
	background-color: #E2A826;
	border-color: #E2A826
}

.btn-aniversario:hover {
	color: #fff;
	background-color: #c7921b;
	border-color: #bc8a19
}

.btn-aniversario:focus,
.btn-aniversario.focus {
	box-shadow: 0 0 0 .2rem rgba(226, 168, 38, 0.5)
}

.btn-aniversario.disabled,
.btn-aniversario:disabled {
	color: #fff;
	background-color: #E2A826;
	border-color: #E2A826
}

.btn-aniversario:not(:disabled):not(.disabled):active,
.btn-aniversario:not(:disabled):not(.disabled).active,
.show>.btn-aniversario.dropdown-toggle {
	color: #fff;
	background-color: #bc8a19;
	border-color: #b18118
}

.btn-aniversario:not(:disabled):not(.disabled):active:focus,
.btn-aniversario:not(:disabled):not(.disabled).active:focus,
.show>.btn-aniversario.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(226, 168, 38, 0.5)
}

.btn-lima {
	color: #212529;
	background-color: #EFFF33;
	border-color: #EFFF33
}

.btn-lima:hover {
	color: #212529;
	background-color: #ecff0d;
	border-color: #ebff00
}

.btn-lima:focus,
.btn-lima.focus {
	box-shadow: 0 0 0 .2rem rgba(239, 255, 51, 0.5)
}

.btn-lima.disabled,
.btn-lima:disabled {
	color: #212529;
	background-color: #EFFF33;
	border-color: #EFFF33
}

.btn-lima:not(:disabled):not(.disabled):active,
.btn-lima:not(:disabled):not(.disabled).active,
.show>.btn-lima.dropdown-toggle {
	color: #212529;
	background-color: #ebff00;
	border-color: #dff200
}

.btn-lima:not(:disabled):not(.disabled):active:focus,
.btn-lima:not(:disabled):not(.disabled).active:focus,
.show>.btn-lima.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(239, 255, 51, 0.5)
}

.btn-azul-ceu {
	color: #fff;
	background-color: #5FC0DD;
	border-color: #5FC0DD
}

.btn-azul-ceu:hover {
	color: #fff;
	background-color: #3fb4d6;
	border-color: #35afd4
}

.btn-azul-ceu:focus,
.btn-azul-ceu.focus {
	box-shadow: 0 0 0 .2rem rgba(95, 192, 221, 0.5)
}

.btn-azul-ceu.disabled,
.btn-azul-ceu:disabled {
	color: #fff;
	background-color: #5FC0DD;
	border-color: #5FC0DD
}

.btn-azul-ceu:not(:disabled):not(.disabled):active,
.btn-azul-ceu:not(:disabled):not(.disabled).active,
.show>.btn-azul-ceu.dropdown-toggle {
	color: #fff;
	background-color: #35afd4;
	border-color: #2caad0
}

.btn-azul-ceu:not(:disabled):not(.disabled):active:focus,
.btn-azul-ceu:not(:disabled):not(.disabled).active:focus,
.show>.btn-azul-ceu.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(95, 192, 221, 0.5)
}

.btn-videoconferencia {
	color: #fff;
	background-color: #8132b1;
	border-color: #8132b1
}

.btn-videoconferencia:hover {
	color: #fff;
	background-color: #6b2a93;
	border-color: #642789
}

.btn-videoconferencia:focus,
.btn-videoconferencia.focus {
	box-shadow: 0 0 0 .2rem rgba(129, 50, 177, 0.5)
}

.btn-videoconferencia.disabled,
.btn-videoconferencia:disabled {
	color: #fff;
	background-color: #8132b1;
	border-color: #8132b1
}

.btn-videoconferencia:not(:disabled):not(.disabled):active,
.btn-videoconferencia:not(:disabled):not(.disabled).active,
.show>.btn-videoconferencia.dropdown-toggle {
	color: #fff;
	background-color: #642789;
	border-color: #5d247f
}

.btn-videoconferencia:not(:disabled):not(.disabled):active:focus,
.btn-videoconferencia:not(:disabled):not(.disabled).active:focus,
.show>.btn-videoconferencia.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(129, 50, 177, 0.5)
}

.btn-outline-primary {
	color: #2798ea;
	background-color: transparent;
	background-image: none;
	border-color: #2798ea
}

.btn-outline-primary:hover {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #2798ea;
	background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-outline-secondary {
	color: #7c858d;
	background-color: transparent;
	background-image: none;
	border-color: #7c858d
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #7c858d;
	border-color: #7c858d
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	box-shadow: 0 0 0 .2rem rgba(124, 133, 141, 0.5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #7c858d;
	background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #7c858d;
	border-color: #7c858d
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(124, 133, 141, 0.5)
}

.btn-outline-success {
	color: #77d600;
	background-color: transparent;
	background-image: none;
	border-color: #77d600
}

.btn-outline-success:hover {
	color: #fff;
	background-color: #77d600;
	border-color: #77d600
}

.btn-outline-success:focus,
.btn-outline-success.focus {
	box-shadow: 0 0 0 .2rem rgba(119, 214, 0, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #77d600;
	background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
	color: #fff;
	background-color: #77d600;
	border-color: #77d600
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(119, 214, 0, 0.5)
}

.btn-outline-info {
	color: #33bCc3;
	background-color: transparent;
	background-image: none;
	border-color: #33bCc3
}

.btn-outline-info:hover {
	color: #fff;
	background-color: #33bCc3;
	border-color: #33bCc3
}

.btn-outline-info:focus,
.btn-outline-info.focus {
	box-shadow: 0 0 0 .2rem rgba(51, 188, 195, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #33bCc3;
	background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: #33bCc3;
	border-color: #33bCc3
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(51, 188, 195, 0.5)
}

.btn-outline-warning {
	color: #efb100;
	background-color: transparent;
	background-image: none;
	border-color: #efb100
}

.btn-outline-warning:hover {
	color: #fff;
	background-color: #efb100;
	border-color: #efb100
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
	box-shadow: 0 0 0 .2rem rgba(239, 177, 0, 0.5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #efb100;
	background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
	color: #fff;
	background-color: #efb100;
	border-color: #efb100
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(239, 177, 0, 0.5)
}

.btn-outline-danger {
	color: #FF8A80;
	background-color: transparent;
	background-image: none;
	border-color: #FF8A80
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #FF8A80;
	border-color: #FF8A80
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: 0 0 0 .2rem rgba(255, 138, 128, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #FF8A80;
	background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: #FF8A80;
	border-color: #FF8A80
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 138, 128, 0.5)
}

.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa
}

.btn-outline-light:hover {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-outline-light:focus,
.btn-outline-light.focus {
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #f8f9fa;
	background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-dark {
	color: #343a40;
	background-color: transparent;
	background-image: none;
	border-color: #343a40
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #343a40;
	background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-dietbox {
	color: #69C600;
	background-color: transparent;
	background-image: none;
	border-color: #69C600
}

.btn-outline-dietbox:hover {
	color: #fff;
	background-color: #69C600;
	border-color: #69C600
}

.btn-outline-dietbox:focus,
.btn-outline-dietbox.focus {
	box-shadow: 0 0 0 .2rem rgba(105, 198, 0, 0.5)
}

.btn-outline-dietbox.disabled,
.btn-outline-dietbox:disabled {
	color: #69C600;
	background-color: transparent
}

.btn-outline-dietbox:not(:disabled):not(.disabled):active,
.btn-outline-dietbox:not(:disabled):not(.disabled).active,
.show>.btn-outline-dietbox.dropdown-toggle {
	color: #fff;
	background-color: #69C600;
	border-color: #69C600
}

.btn-outline-dietbox:not(:disabled):not(.disabled):active:focus,
.btn-outline-dietbox:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dietbox.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(105, 198, 0, 0.5)
}

.btn-outline-dietbox-secondary {
	color: #5C5091;
	background-color: transparent;
	background-image: none;
	border-color: #5C5091
}

.btn-outline-dietbox-secondary:hover {
	color: #fff;
	background-color: #5C5091;
	border-color: #5C5091
}

.btn-outline-dietbox-secondary:focus,
.btn-outline-dietbox-secondary.focus {
	box-shadow: 0 0 0 .2rem rgba(92, 80, 145, 0.5)
}

.btn-outline-dietbox-secondary.disabled,
.btn-outline-dietbox-secondary:disabled {
	color: #5C5091;
	background-color: transparent
}

.btn-outline-dietbox-secondary:not(:disabled):not(.disabled):active,
.btn-outline-dietbox-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-dietbox-secondary.dropdown-toggle {
	color: #fff;
	background-color: #5C5091;
	border-color: #5C5091
}

.btn-outline-dietbox-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-dietbox-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dietbox-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(92, 80, 145, 0.5)
}

.btn-outline-dietbox-app {
	color: #F2B705;
	background-color: transparent;
	background-image: none;
	border-color: #F2B705
}

.btn-outline-dietbox-app:hover {
	color: #212529;
	background-color: #F2B705;
	border-color: #F2B705
}

.btn-outline-dietbox-app:focus,
.btn-outline-dietbox-app.focus {
	box-shadow: 0 0 0 .2rem rgba(242, 183, 5, 0.5)
}

.btn-outline-dietbox-app.disabled,
.btn-outline-dietbox-app:disabled {
	color: #F2B705;
	background-color: transparent
}

.btn-outline-dietbox-app:not(:disabled):not(.disabled):active,
.btn-outline-dietbox-app:not(:disabled):not(.disabled).active,
.show>.btn-outline-dietbox-app.dropdown-toggle {
	color: #212529;
	background-color: #F2B705;
	border-color: #F2B705
}

.btn-outline-dietbox-app:not(:disabled):not(.disabled):active:focus,
.btn-outline-dietbox-app:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dietbox-app.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(242, 183, 5, 0.5)
}

.btn-outline-pro {
	color: #FD0;
	background-color: transparent;
	background-image: none;
	border-color: #FD0
}

.btn-outline-pro:hover {
	color: #212529;
	background-color: #FD0;
	border-color: #FD0
}

.btn-outline-pro:focus,
.btn-outline-pro.focus {
	box-shadow: 0 0 0 .2rem rgba(255, 221, 0, 0.5)
}

.btn-outline-pro.disabled,
.btn-outline-pro:disabled {
	color: #FD0;
	background-color: transparent
}

.btn-outline-pro:not(:disabled):not(.disabled):active,
.btn-outline-pro:not(:disabled):not(.disabled).active,
.show>.btn-outline-pro.dropdown-toggle {
	color: #212529;
	background-color: #FD0;
	border-color: #FD0
}

.btn-outline-pro:not(:disabled):not(.disabled):active:focus,
.btn-outline-pro:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-pro.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(255, 221, 0, 0.5)
}

.btn-outline-recebafacil {
	color: #5F7D8C;
	background-color: transparent;
	background-image: none;
	border-color: #5F7D8C
}

.btn-outline-recebafacil:hover {
	color: #fff;
	background-color: #5F7D8C;
	border-color: #5F7D8C
}

.btn-outline-recebafacil:focus,
.btn-outline-recebafacil.focus {
	box-shadow: 0 0 0 .2rem rgba(95, 125, 140, 0.5)
}

.btn-outline-recebafacil.disabled,
.btn-outline-recebafacil:disabled {
	color: #5F7D8C;
	background-color: transparent
}

.btn-outline-recebafacil:not(:disabled):not(.disabled):active,
.btn-outline-recebafacil:not(:disabled):not(.disabled).active,
.show>.btn-outline-recebafacil.dropdown-toggle {
	color: #fff;
	background-color: #5F7D8C;
	border-color: #5F7D8C
}

.btn-outline-recebafacil:not(:disabled):not(.disabled):active:focus,
.btn-outline-recebafacil:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-recebafacil.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(95, 125, 140, 0.5)
}

.btn-outline-qpc {
	color: #d24297;
	background-color: transparent;
	background-image: none;
	border-color: #d24297
}

.btn-outline-qpc:hover {
	color: #fff;
	background-color: #d24297;
	border-color: #d24297
}

.btn-outline-qpc:focus,
.btn-outline-qpc.focus {
	box-shadow: 0 0 0 .2rem rgba(210, 66, 151, 0.5)
}

.btn-outline-qpc.disabled,
.btn-outline-qpc:disabled {
	color: #d24297;
	background-color: transparent
}

.btn-outline-qpc:not(:disabled):not(.disabled):active,
.btn-outline-qpc:not(:disabled):not(.disabled).active,
.show>.btn-outline-qpc.dropdown-toggle {
	color: #fff;
	background-color: #d24297;
	border-color: #d24297
}

.btn-outline-qpc:not(:disabled):not(.disabled):active:focus,
.btn-outline-qpc:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-qpc.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(210, 66, 151, 0.5)
}

.btn-outline-anamnese {
	color: #F46B59;
	background-color: transparent;
	background-image: none;
	border-color: #F46B59
}

.btn-outline-anamnese:hover {
	color: #fff;
	background-color: #F46B59;
	border-color: #F46B59
}

.btn-outline-anamnese:focus,
.btn-outline-anamnese.focus {
	box-shadow: 0 0 0 .2rem rgba(244, 107, 89, 0.5)
}

.btn-outline-anamnese.disabled,
.btn-outline-anamnese:disabled {
	color: #F46B59;
	background-color: transparent
}

.btn-outline-anamnese:not(:disabled):not(.disabled):active,
.btn-outline-anamnese:not(:disabled):not(.disabled).active,
.show>.btn-outline-anamnese.dropdown-toggle {
	color: #fff;
	background-color: #F46B59;
	border-color: #F46B59
}

.btn-outline-anamnese:not(:disabled):not(.disabled):active:focus,
.btn-outline-anamnese:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-anamnese.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(244, 107, 89, 0.5)
}

.btn-outline-antropometria {
	color: #9149BD;
	background-color: transparent;
	background-image: none;
	border-color: #9149BD
}

.btn-outline-antropometria:hover {
	color: #fff;
	background-color: #9149BD;
	border-color: #9149BD
}

.btn-outline-antropometria:focus,
.btn-outline-antropometria.focus {
	box-shadow: 0 0 0 .2rem rgba(145, 73, 189, 0.5)
}

.btn-outline-antropometria.disabled,
.btn-outline-antropometria:disabled {
	color: #9149BD;
	background-color: transparent
}

.btn-outline-antropometria:not(:disabled):not(.disabled):active,
.btn-outline-antropometria:not(:disabled):not(.disabled).active,
.show>.btn-outline-antropometria.dropdown-toggle {
	color: #fff;
	background-color: #9149BD;
	border-color: #9149BD
}

.btn-outline-antropometria:not(:disabled):not(.disabled):active:focus,
.btn-outline-antropometria:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-antropometria.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(145, 73, 189, 0.5)
}

.btn-outline-plano-alimentar {
	color: #60B738;
	background-color: transparent;
	background-image: none;
	border-color: #60B738
}

.btn-outline-plano-alimentar:hover {
	color: #fff;
	background-color: #60B738;
	border-color: #60B738
}

.btn-outline-plano-alimentar:focus,
.btn-outline-plano-alimentar.focus {
	box-shadow: 0 0 0 .2rem rgba(96, 183, 56, 0.5)
}

.btn-outline-plano-alimentar.disabled,
.btn-outline-plano-alimentar:disabled {
	color: #60B738;
	background-color: transparent
}

.btn-outline-plano-alimentar:not(:disabled):not(.disabled):active,
.btn-outline-plano-alimentar:not(:disabled):not(.disabled).active,
.show>.btn-outline-plano-alimentar.dropdown-toggle {
	color: #fff;
	background-color: #60B738;
	border-color: #60B738
}

.btn-outline-plano-alimentar:not(:disabled):not(.disabled):active:focus,
.btn-outline-plano-alimentar:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-plano-alimentar.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(96, 183, 56, 0.5)
}

.btn-outline-plano-alimentar-light {
	color: #C9E3DB;
	background-color: transparent;
	background-image: none;
	border-color: #C9E3DB
}

.btn-outline-plano-alimentar-light:hover {
	color: #212529;
	background-color: #C9E3DB;
	border-color: #C9E3DB
}

.btn-outline-plano-alimentar-light:focus,
.btn-outline-plano-alimentar-light.focus {
	box-shadow: 0 0 0 .2rem rgba(201, 227, 219, 0.5)
}

.btn-outline-plano-alimentar-light.disabled,
.btn-outline-plano-alimentar-light:disabled {
	color: #C9E3DB;
	background-color: transparent
}

.btn-outline-plano-alimentar-light:not(:disabled):not(.disabled):active,
.btn-outline-plano-alimentar-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-plano-alimentar-light.dropdown-toggle {
	color: #212529;
	background-color: #C9E3DB;
	border-color: #C9E3DB
}

.btn-outline-plano-alimentar-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-plano-alimentar-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-plano-alimentar-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(201, 227, 219, 0.5)
}

.btn-outline-recordatorio {
	color: #FAAF33;
	background-color: transparent;
	background-image: none;
	border-color: #FAAF33
}

.btn-outline-recordatorio:hover {
	color: #212529;
	background-color: #FAAF33;
	border-color: #FAAF33
}

.btn-outline-recordatorio:focus,
.btn-outline-recordatorio.focus {
	box-shadow: 0 0 0 .2rem rgba(250, 175, 51, 0.5)
}

.btn-outline-recordatorio.disabled,
.btn-outline-recordatorio:disabled {
	color: #FAAF33;
	background-color: transparent
}

.btn-outline-recordatorio:not(:disabled):not(.disabled):active,
.btn-outline-recordatorio:not(:disabled):not(.disabled).active,
.show>.btn-outline-recordatorio.dropdown-toggle {
	color: #212529;
	background-color: #FAAF33;
	border-color: #FAAF33
}

.btn-outline-recordatorio:not(:disabled):not(.disabled):active:focus,
.btn-outline-recordatorio:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-recordatorio.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(250, 175, 51, 0.5)
}

.btn-outline-gasto-energetico {
	color: #26A6D5;
	background-color: transparent;
	background-image: none;
	border-color: #26A6D5
}

.btn-outline-gasto-energetico:hover {
	color: #fff;
	background-color: #26A6D5;
	border-color: #26A6D5
}

.btn-outline-gasto-energetico:focus,
.btn-outline-gasto-energetico.focus {
	box-shadow: 0 0 0 .2rem rgba(38, 166, 213, 0.5)
}

.btn-outline-gasto-energetico.disabled,
.btn-outline-gasto-energetico:disabled {
	color: #26A6D5;
	background-color: transparent
}

.btn-outline-gasto-energetico:not(:disabled):not(.disabled):active,
.btn-outline-gasto-energetico:not(:disabled):not(.disabled).active,
.show>.btn-outline-gasto-energetico.dropdown-toggle {
	color: #fff;
	background-color: #26A6D5;
	border-color: #26A6D5
}

.btn-outline-gasto-energetico:not(:disabled):not(.disabled):active:focus,
.btn-outline-gasto-energetico:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-gasto-energetico.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(38, 166, 213, 0.5)
}

.btn-outline-avaliacao-laboratorial {
	color: #33797F;
	background-color: transparent;
	background-image: none;
	border-color: #33797F
}

.btn-outline-avaliacao-laboratorial:hover {
	color: #fff;
	background-color: #33797F;
	border-color: #33797F
}

.btn-outline-avaliacao-laboratorial:focus,
.btn-outline-avaliacao-laboratorial.focus {
	box-shadow: 0 0 0 .2rem rgba(51, 121, 127, 0.5)
}

.btn-outline-avaliacao-laboratorial.disabled,
.btn-outline-avaliacao-laboratorial:disabled {
	color: #33797F;
	background-color: transparent
}

.btn-outline-avaliacao-laboratorial:not(:disabled):not(.disabled):active,
.btn-outline-avaliacao-laboratorial:not(:disabled):not(.disabled).active,
.show>.btn-outline-avaliacao-laboratorial.dropdown-toggle {
	color: #fff;
	background-color: #33797F;
	border-color: #33797F
}

.btn-outline-avaliacao-laboratorial:not(:disabled):not(.disabled):active:focus,
.btn-outline-avaliacao-laboratorial:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-avaliacao-laboratorial.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(51, 121, 127, 0.5)
}

.btn-outline-prescricao {
	color: #384C84;
	background-color: transparent;
	background-image: none;
	border-color: #384C84
}

.btn-outline-prescricao:hover {
	color: #fff;
	background-color: #384C84;
	border-color: #384C84
}

.btn-outline-prescricao:focus,
.btn-outline-prescricao.focus {
	box-shadow: 0 0 0 .2rem rgba(56, 76, 132, 0.5)
}

.btn-outline-prescricao.disabled,
.btn-outline-prescricao:disabled {
	color: #384C84;
	background-color: transparent
}

.btn-outline-prescricao:not(:disabled):not(.disabled):active,
.btn-outline-prescricao:not(:disabled):not(.disabled).active,
.show>.btn-outline-prescricao.dropdown-toggle {
	color: #fff;
	background-color: #384C84;
	border-color: #384C84
}

.btn-outline-prescricao:not(:disabled):not(.disabled):active:focus,
.btn-outline-prescricao:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-prescricao.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(56, 76, 132, 0.5)
}

.btn-outline-consulta {
	color: #2798ea;
	background-color: transparent;
	background-image: none;
	border-color: #2798ea
}

.btn-outline-consulta:hover {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-outline-consulta:focus,
.btn-outline-consulta.focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-outline-consulta.disabled,
.btn-outline-consulta:disabled {
	color: #2798ea;
	background-color: transparent
}

.btn-outline-consulta:not(:disabled):not(.disabled):active,
.btn-outline-consulta:not(:disabled):not(.disabled).active,
.show>.btn-outline-consulta.dropdown-toggle {
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.btn-outline-consulta:not(:disabled):not(.disabled):active:focus,
.btn-outline-consulta:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-consulta.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.5)
}

.btn-outline-retorno {
	color: #009788;
	background-color: transparent;
	background-image: none;
	border-color: #009788
}

.btn-outline-retorno:hover {
	color: #fff;
	background-color: #009788;
	border-color: #009788
}

.btn-outline-retorno:focus,
.btn-outline-retorno.focus {
	box-shadow: 0 0 0 .2rem rgba(0, 151, 136, 0.5)
}

.btn-outline-retorno.disabled,
.btn-outline-retorno:disabled {
	color: #009788;
	background-color: transparent
}

.btn-outline-retorno:not(:disabled):not(.disabled):active,
.btn-outline-retorno:not(:disabled):not(.disabled).active,
.show>.btn-outline-retorno.dropdown-toggle {
	color: #fff;
	background-color: #009788;
	border-color: #009788
}

.btn-outline-retorno:not(:disabled):not(.disabled):active:focus,
.btn-outline-retorno:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-retorno.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(0, 151, 136, 0.5)
}

.btn-outline-previsao-retorno {
	color: #81286D;
	background-color: transparent;
	background-image: none;
	border-color: #81286D
}

.btn-outline-previsao-retorno:hover {
	color: #fff;
	background-color: #81286D;
	border-color: #81286D
}

.btn-outline-previsao-retorno:focus,
.btn-outline-previsao-retorno.focus {
	box-shadow: 0 0 0 .2rem rgba(129, 40, 109, 0.5)
}

.btn-outline-previsao-retorno.disabled,
.btn-outline-previsao-retorno:disabled {
	color: #81286D;
	background-color: transparent
}

.btn-outline-previsao-retorno:not(:disabled):not(.disabled):active,
.btn-outline-previsao-retorno:not(:disabled):not(.disabled).active,
.show>.btn-outline-previsao-retorno.dropdown-toggle {
	color: #fff;
	background-color: #81286D;
	border-color: #81286D
}

.btn-outline-previsao-retorno:not(:disabled):not(.disabled):active:focus,
.btn-outline-previsao-retorno:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-previsao-retorno.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(129, 40, 109, 0.5)
}

.btn-outline-aniversario {
	color: #E2A826;
	background-color: transparent;
	background-image: none;
	border-color: #E2A826
}

.btn-outline-aniversario:hover {
	color: #fff;
	background-color: #E2A826;
	border-color: #E2A826
}

.btn-outline-aniversario:focus,
.btn-outline-aniversario.focus {
	box-shadow: 0 0 0 .2rem rgba(226, 168, 38, 0.5)
}

.btn-outline-aniversario.disabled,
.btn-outline-aniversario:disabled {
	color: #E2A826;
	background-color: transparent
}

.btn-outline-aniversario:not(:disabled):not(.disabled):active,
.btn-outline-aniversario:not(:disabled):not(.disabled).active,
.show>.btn-outline-aniversario.dropdown-toggle {
	color: #fff;
	background-color: #E2A826;
	border-color: #E2A826
}

.btn-outline-aniversario:not(:disabled):not(.disabled):active:focus,
.btn-outline-aniversario:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-aniversario.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(226, 168, 38, 0.5)
}

.btn-outline-lima {
	color: #EFFF33;
	background-color: transparent;
	background-image: none;
	border-color: #EFFF33
}

.btn-outline-lima:hover {
	color: #212529;
	background-color: #EFFF33;
	border-color: #EFFF33
}

.btn-outline-lima:focus,
.btn-outline-lima.focus {
	box-shadow: 0 0 0 .2rem rgba(239, 255, 51, 0.5)
}

.btn-outline-lima.disabled,
.btn-outline-lima:disabled {
	color: #EFFF33;
	background-color: transparent
}

.btn-outline-lima:not(:disabled):not(.disabled):active,
.btn-outline-lima:not(:disabled):not(.disabled).active,
.show>.btn-outline-lima.dropdown-toggle {
	color: #212529;
	background-color: #EFFF33;
	border-color: #EFFF33
}

.btn-outline-lima:not(:disabled):not(.disabled):active:focus,
.btn-outline-lima:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-lima.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(239, 255, 51, 0.5)
}

.btn-outline-azul-ceu {
	color: #5FC0DD;
	background-color: transparent;
	background-image: none;
	border-color: #5FC0DD
}

.btn-outline-azul-ceu:hover {
	color: #fff;
	background-color: #5FC0DD;
	border-color: #5FC0DD
}

.btn-outline-azul-ceu:focus,
.btn-outline-azul-ceu.focus {
	box-shadow: 0 0 0 .2rem rgba(95, 192, 221, 0.5)
}

.btn-outline-azul-ceu.disabled,
.btn-outline-azul-ceu:disabled {
	color: #5FC0DD;
	background-color: transparent
}

.btn-outline-azul-ceu:not(:disabled):not(.disabled):active,
.btn-outline-azul-ceu:not(:disabled):not(.disabled).active,
.show>.btn-outline-azul-ceu.dropdown-toggle {
	color: #fff;
	background-color: #5FC0DD;
	border-color: #5FC0DD
}

.btn-outline-azul-ceu:not(:disabled):not(.disabled):active:focus,
.btn-outline-azul-ceu:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-azul-ceu.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(95, 192, 221, 0.5)
}

.btn-outline-videoconferencia {
	color: #8132b1;
	background-color: transparent;
	background-image: none;
	border-color: #8132b1
}

.btn-outline-videoconferencia:hover {
	color: #fff;
	background-color: #8132b1;
	border-color: #8132b1
}

.btn-outline-videoconferencia:focus,
.btn-outline-videoconferencia.focus {
	box-shadow: 0 0 0 .2rem rgba(129, 50, 177, 0.5)
}

.btn-outline-videoconferencia.disabled,
.btn-outline-videoconferencia:disabled {
	color: #8132b1;
	background-color: transparent
}

.btn-outline-videoconferencia:not(:disabled):not(.disabled):active,
.btn-outline-videoconferencia:not(:disabled):not(.disabled).active,
.show>.btn-outline-videoconferencia.dropdown-toggle {
	color: #fff;
	background-color: #8132b1;
	border-color: #8132b1
}

.btn-outline-videoconferencia:not(:disabled):not(.disabled):active:focus,
.btn-outline-videoconferencia:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-videoconferencia.dropdown-toggle:focus {
	box-shadow: 0 0 0 .2rem rgba(129, 50, 177, 0.5)
}

.btn-link {
	font-weight: 400;
	color: #2798ea;
	background-color: transparent
}

.btn-link:hover {
	color: #116fb3;
	text-decoration: underline;
	background-color: transparent;
	border-color: transparent
}

.btn-link:focus,
.btn-link.focus {
	text-decoration: underline;
	border-color: transparent;
	box-shadow: none
}

.btn-link:disabled,
.btn-link.disabled {
	color: #7c858d;
	pointer-events: none
}

.btn-lg,
.btn-group-lg>.btn {
	padding: .5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: .3rem
}

.btn-sm,
.btn-group-sm>.btn {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem
}

.btn-block {
	display: block;
	width: 100%
}

.btn-block+.btn-block {
	margin-top: .5rem
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%
}

.fade {
	transition: opacity .15s linear
}

@media screen and (prefers-reduced-motion:reduce) {
	.fade {
		transition: none
	}
}

.fade:not(.show) {
	opacity: 0
}

.collapse:not(.show) {
	display: none
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height .35s ease
}

@media screen and (prefers-reduced-motion:reduce) {
	.collapsing {
		transition: none
	}
}

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
	margin-left: 0
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: .5rem 0;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: #495057;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: .25rem
}

.dropdown-menu-right {
	right: 0;
	left: auto
}

.dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: 0;
	border-right: .3em solid transparent;
	border-bottom: .3em solid;
	border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropright .dropdown-menu {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: .125rem
}

.dropright .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: 0;
	border-bottom: .3em solid transparent;
	border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropright .dropdown-toggle::after {
	vertical-align: 0
}

.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: .255em;
	vertical-align: .255em;
	content: ""
}

.dropleft .dropdown-toggle::after {
	display: none
}

.dropleft .dropdown-toggle::before {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: .3em solid;
	border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropleft .dropdown-toggle::before {
	vertical-align: 0
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
	right: auto;
	bottom: auto
}

.dropdown-divider {
	height: 0;
	margin: .5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: .25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #2798ea
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #7c858d;
	background-color: transparent
}

.dropdown-menu.show {
	display: block
}

.dropdown-header {
	display: block;
	padding: .5rem 1.5rem;
	margin-bottom: 0;
	font-size: .875rem;
	color: #7c858d;
	white-space: nowrap
}

.dropdown-item-text {
	display: block;
	padding: .25rem 1.5rem;
	color: #212529
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	flex: 0 1 auto
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
	z-index: 1
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
	z-index: 1
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
	margin-left: -1px
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start
}

.btn-toolbar .input-group {
	width: auto
}

.btn-group>.btn:first-child {
	margin-left: 0
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.dropdown-toggle-split {
	padding-right: .5625rem;
	padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
	margin-right: 0
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
	padding-right: .375rem;
	padding-left: .375rem
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
	padding-right: .75rem;
	padding-left: .75rem
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
	width: 100%
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
	margin-top: -1px;
	margin-left: 0
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
	margin-bottom: 0
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%
}

.input-group>.form-control,
.input-group>.custom-select,
.input-group>.custom-file {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
	margin-left: -1px
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
	z-index: 3
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.input-group>.custom-file {
	display: flex;
	align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.input-group-prepend,
.input-group-append {
	display: flex
}

.input-group-prepend .btn,
.input-group-append .btn {
	position: relative;
	z-index: 2
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
	margin-left: -1px
}

.input-group-prepend {
	margin-right: -1px
}

.input-group-append {
	margin-left: -1px
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: .375rem .75rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: .25rem
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
	margin-top: 0
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem
}

.custom-control-inline {
	display: inline-flex;
	margin-right: 1rem
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	background-color: #2798ea
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.custom-control-input:active~.custom-control-label::before {
	color: #fff;
	background-color: #cae6fa
}

.custom-control-input:disabled~.custom-control-label {
	color: #7c858d
}

.custom-control-input:disabled~.custom-control-label::before {
	background-color: #e9ecef
}

.custom-control-label {
	position: relative;
	margin-bottom: 0
}

.custom-control-label::before {
	position: absolute;
	top: .25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	user-select: none;
	background-color: #dee2e6
}

.custom-control-label::after {
	position: absolute;
	top: .25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: "";
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%
}

.custom-checkbox .custom-control-label::before {
	border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: #2798ea
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
	background-color: #2798ea
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(39, 152, 234, 0.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
	background-color: rgba(39, 152, 234, 0.5)
}

.custom-radio .custom-control-label::before {
	border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: #2798ea
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(39, 152, 234, 0.5)
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: .375rem 1.75rem .375rem .75rem;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
	background-size: 8px 10px;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	appearance: none
}

.custom-select:focus {
	border-color: #9bcff5;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(155, 207, 245, 0.5)
}

.custom-select:focus::-ms-value {
	color: #495057;
	background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
	height: auto;
	padding-right: .75rem;
	background-image: none
}

.custom-select:disabled {
	color: #7c858d;
	background-color: #e9ecef
}

.custom-select::-ms-expand {
	opacity: 0
}

.custom-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: .375rem;
	padding-bottom: .375rem;
	font-size: 75%
}

.custom-select-lg {
	height: calc(2.875rem + 2px);
	padding-top: .375rem;
	padding-bottom: .375rem;
	font-size: 125%
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin-bottom: 0
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin: 0;
	opacity: 0
}

.custom-file-input:focus~.custom-file-label {
	border-color: #9bcff5;
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.custom-file-input:focus~.custom-file-label::after {
	border-color: #9bcff5
}

.custom-file-input:disabled~.custom-file-label {
	background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
	content: "Browse"
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: calc(2.25rem + 2px);
	padding: .375rem .75rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: .25rem
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: 2.25rem;
	padding: .375rem .75rem;
	line-height: 1.5;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: 1px solid #ced4da;
	border-radius: 0 .25rem .25rem 0
}

.custom-range {
	width: 100%;
	padding-left: 0;
	background-color: transparent;
	appearance: none
}

.custom-range:focus {
	outline: 0
}

.custom-range::-moz-focus-outer {
	border: 0
}

.custom-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #2798ea;
	border: 0;
	border-radius: 1rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	appearance: none
}

@media screen and (prefers-reduced-motion:reduce) {
	.custom-range::-webkit-slider-thumb {
		transition: none
	}
}

.custom-range::-webkit-slider-thumb:focus {
	outline: 0;
	box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.custom-range::-webkit-slider-thumb:active {
	background-color: #cae6fa
}

.custom-range::-webkit-slider-runnable-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem
}

.custom-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #2798ea;
	border: 0;
	border-radius: 1rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	appearance: none
}

@media screen and (prefers-reduced-motion:reduce) {
	.custom-range::-moz-range-thumb {
		transition: none
	}
}

.custom-range::-moz-range-thumb:focus {
	outline: 0;
	box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.custom-range::-moz-range-thumb:active {
	background-color: #cae6fa
}

.custom-range::-moz-range-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem
}

.custom-range::-ms-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #2798ea;
	border: 0;
	border-radius: 1rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	appearance: none
}

@media screen and (prefers-reduced-motion:reduce) {
	.custom-range::-ms-thumb {
		transition: none
	}
}

.custom-range::-ms-thumb:focus {
	outline: 0;
	box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.custom-range::-ms-thumb:active {
	background-color: #cae6fa
}

.custom-range::-ms-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: transparent;
	border-color: transparent;
	border-width: .5rem
}

.custom-range::-ms-fill-lower {
	background-color: #dee2e6;
	border-radius: 1rem
}

.custom-range::-ms-fill-upper {
	margin-right: 15px;
	background-color: #dee2e6;
	border-radius: 1rem
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media screen and (prefers-reduced-motion:reduce) {
	.custom-control-label::before,
	.custom-file-label,
	.custom-select {
		transition: none
	}
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.nav-link {
	display: block;
	padding: .5rem 1rem
}

.nav-link:hover,
.nav-link:focus {
	text-decoration: none
}

.nav-link.disabled {
	color: #7c858d
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
	margin-bottom: -1px
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
	color: #7c858d;
	background-color: transparent;
	border-color: transparent
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #f8f9fa;
	border-color: #dee2e6 #dee2e6 #f8f9fa
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.nav-pills .nav-link {
	border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #fff;
	background-color: #2798ea
}

.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center
}

.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center
}

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between
}

.navbar-brand {
	display: inline-block;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap
}

.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none
}

.navbar-text {
	display: inline-block;
	padding-top: .5rem;
	padding-bottom: .5rem
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center
}

.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem
}

.navbar-toggler:hover,
.navbar-toggler:focus {
	text-decoration: none
}

.navbar-toggler:not(:disabled):not(.disabled) {
	cursor: pointer
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%
}

@media(max-width:575.98px) {
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid {
		padding-right: 0;
		padding-left: 0
	}
}

@media(min-width:576px) {
	.navbar-expand-sm {
		flex-flow: row nowrap;
		justify-content: flex-start
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid {
		flex-wrap: nowrap
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-sm .navbar-toggler {
		display: none
	}
}

@media(max-width:767.98px) {
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid {
		padding-right: 0;
		padding-left: 0
	}
}

@media(min-width:768px) {
	.navbar-expand-md {
		flex-flow: row nowrap;
		justify-content: flex-start
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid {
		flex-wrap: nowrap
	}
	.navbar-expand-md .navbar-collapse {
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-md .navbar-toggler {
		display: none
	}
}

@media(max-width:991.98px) {
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid {
		padding-right: 0;
		padding-left: 0
	}
}

@media(min-width:992px) {
	.navbar-expand-lg {
		flex-flow: row nowrap;
		justify-content: flex-start
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid {
		flex-wrap: nowrap
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-lg .navbar-toggler {
		display: none
	}
}

@media(max-width:1199.98px) {
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid {
		padding-right: 0;
		padding-left: 0
	}
}

@media(min-width:1200px) {
	.navbar-expand-xl {
		flex-flow: row nowrap;
		justify-content: flex-start
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem
	}
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid {
		flex-wrap: nowrap
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex!important;
		flex-basis: auto
	}
	.navbar-expand-xl .navbar-toggler {
		display: none
	}
}

.navbar-expand {
	flex-flow: row nowrap;
	justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
	padding-right: 0;
	padding-left: 0
}

.navbar-expand .navbar-nav {
	flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: .5rem;
	padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
	flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
	display: flex!important;
	flex-basis: auto
}

.navbar-expand .navbar-toggler {
	display: none
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.1)
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-text a {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-dark .navbar-brand {
	color: #fff
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25)
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.5);
	border-color: rgba(255, 255, 255, 0.1)
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-text a {
	color: #fff
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: #fff
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: .25rem
}

.card>hr {
	margin-right: 0;
	margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.card-body {
	flex: 1 1 auto;
	padding: 1.25rem
}

.card-title {
	margin-bottom: .75rem
}

.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0
}

.card-text:last-child {
	margin-bottom: 0
}

.card-link:hover {
	text-decoration: none
}

.card-link+.card-link {
	margin-left: 1.25rem
}

.card-header {
	padding: .75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125)
}

.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
	border-top: 0
}

.card-footer {
	padding: .75rem 1.25rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}

.card-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0
}

.card-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem
}

.card-img {
	width: 100%;
	border-radius: calc(0.25rem - 1px)
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px)
}

.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px)
}

.card-deck {
	display: flex;
	flex-direction: column
}

.card-deck .card {
	margin-bottom: 15px
}

@media(min-width:576px) {
	.card-deck {
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px
	}
	.card-deck .card {
		display: flex;
		flex: 1 0 0;
		flex-direction: column;
		margin-right: 15px;
		margin-bottom: 0;
		margin-left: 15px
	}
}

.card-group {
	display: flex;
	flex-direction: column
}

.card-group>.card {
	margin-bottom: 15px
}

@media(min-width:576px) {
	.card-group {
		flex-flow: row wrap
	}
	.card-group>.card {
		flex: 1 0 0;
		margin-bottom: 0
	}
	.card-group>.card+.card {
		margin-left: 0;
		border-left: 0
	}
	.card-group>.card:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0
	}
	.card-group>.card:first-child .card-img-top,
	.card-group>.card:first-child .card-header {
		border-top-right-radius: 0
	}
	.card-group>.card:first-child .card-img-bottom,
	.card-group>.card:first-child .card-footer {
		border-bottom-right-radius: 0
	}
	.card-group>.card:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0
	}
	.card-group>.card:last-child .card-img-top,
	.card-group>.card:last-child .card-header {
		border-top-left-radius: 0
	}
	.card-group>.card:last-child .card-img-bottom,
	.card-group>.card:last-child .card-footer {
		border-bottom-left-radius: 0
	}
	.card-group>.card:only-child {
		border-radius: .25rem
	}
	.card-group>.card:only-child .card-img-top,
	.card-group>.card:only-child .card-header {
		border-top-left-radius: .25rem;
		border-top-right-radius: .25rem
	}
	.card-group>.card:only-child .card-img-bottom,
	.card-group>.card:only-child .card-footer {
		border-bottom-right-radius: .25rem;
		border-bottom-left-radius: .25rem
	}
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
		border-radius: 0
	}
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
	.card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
		border-radius: 0
	}
}

.card-columns .card {
	margin-bottom: .75rem
}

@media(min-width:576px) {
	.card-columns {
		column-count: 3;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1
	}
	.card-columns .card {
		display: inline-block;
		width: 100%
	}
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 0;
	border-radius: 0
}

.accordion .card:not(:first-of-type) .card-header:first-child {
	border-radius: 0
}

.accordion .card:first-of-type {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.accordion .card:last-of-type {
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #e9ecef;
	border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
	display: inline-block;
	padding-right: .5rem;
	color: #7c858d;
	content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
	text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
	text-decoration: none
}

.breadcrumb-item.active {
	color: #7c858d
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem
}

.page-link {
	position: relative;
	display: block;
	padding: .5rem .75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #2798ea;
	background-color: #fff;
	border: 1px solid #dee2e6
}

.page-link:hover {
	z-index: 2;
	color: #116fb3;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6
}

.page-link:focus {
	z-index: 2;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(39, 152, 234, 0.25)
}

.page-link:not(:disabled):not(.disabled) {
	cursor: pointer
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.page-item.disabled .page-link {
	color: #7c858d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6
}

.pagination-lg .page-link {
	padding: .75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: .3rem;
	border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: .3rem;
	border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: .2rem;
	border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: .2rem;
	border-bottom-right-radius: .2rem
}

.badge {
	display: inline-block;
	padding: .25em .4em;
	font-size: 75%;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25rem
}

.badge:empty {
	display: none
}

.btn .badge {
	position: relative;
	top: -1px
}

.badge-pill {
	padding-right: .6em;
	padding-left: .6em;
	border-radius: 10rem
}

.badge-primary {
	color: #fff;
	background-color: #2798ea
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #147eca
}

.badge-secondary {
	color: #fff;
	background-color: #7c858d
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #646b72
}

.badge-success {
	color: #fff;
	background-color: #77d600
}

.badge-success[href]:hover,
.badge-success[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #5ba300
}

.badge-info {
	color: #fff;
	background-color: #33bCc3
}

.badge-info[href]:hover,
.badge-info[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #28959b
}

.badge-warning {
	color: #fff;
	background-color: #efb100
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #bc8b00
}

.badge-danger {
	color: #fff;
	background-color: #FF8A80
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #ff5b4d
}

.badge-light {
	color: #212529;
	background-color: #f8f9fa
}

.badge-light[href]:hover,
.badge-light[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #dae0e5
}

.badge-dark {
	color: #fff;
	background-color: #343a40
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #1d2124
}

.badge-dietbox {
	color: #fff;
	background-color: #69C600
}

.badge-dietbox[href]:hover,
.badge-dietbox[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #4e9300
}

.badge-dietbox-secondary {
	color: #fff;
	background-color: #5C5091
}

.badge-dietbox-secondary[href]:hover,
.badge-dietbox-secondary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #473e70
}

.badge-dietbox-app {
	color: #212529;
	background-color: #F2B705
}

.badge-dietbox-app[href]:hover,
.badge-dietbox-app[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #c09104
}

.badge-pro {
	color: #212529;
	background-color: #FD0
}

.badge-pro[href]:hover,
.badge-pro[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #ccb100
}

.badge-recebafacil {
	color: #fff;
	background-color: #5F7D8C
}

.badge-recebafacil[href]:hover,
.badge-recebafacil[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #4a626e
}

.badge-qpc {
	color: #fff;
	background-color: #d24297
}

.badge-qpc[href]:hover,
.badge-qpc[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #b62b7d
}

.badge-anamnese {
	color: #fff;
	background-color: #F46B59
}

.badge-anamnese[href]:hover,
.badge-anamnese[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #f14029
}

.badge-antropometria {
	color: #fff;
	background-color: #9149BD
}

.badge-antropometria[href]:hover,
.badge-antropometria[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #75389b
}

.badge-plano-alimentar {
	color: #fff;
	background-color: #60B738
}

.badge-plano-alimentar[href]:hover,
.badge-plano-alimentar[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #4c902c
}

.badge-plano-alimentar-light {
	color: #212529;
	background-color: #C9E3DB
}

.badge-plano-alimentar-light[href]:hover,
.badge-plano-alimentar-light[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #a7d2c5
}

.badge-recordatorio {
	color: #212529;
	background-color: #FAAF33
}

.badge-recordatorio[href]:hover,
.badge-recordatorio[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #f49a06
}

.badge-gasto-energetico {
	color: #fff;
	background-color: #26A6D5
}

.badge-gasto-energetico[href]:hover,
.badge-gasto-energetico[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #1e84aa
}

.badge-avaliacao-laboratorial {
	color: #fff;
	background-color: #33797F
}

.badge-avaliacao-laboratorial[href]:hover,
.badge-avaliacao-laboratorial[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #24565b
}

.badge-prescricao {
	color: #fff;
	background-color: #384C84
}

.badge-prescricao[href]:hover,
.badge-prescricao[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #293760
}

.badge-consulta {
	color: #fff;
	background-color: #2798ea
}

.badge-consulta[href]:hover,
.badge-consulta[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #147eca
}

.badge-retorno {
	color: #fff;
	background-color: #009788
}

.badge-retorno[href]:hover,
.badge-retorno[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #00645a
}

.badge-previsao-retorno {
	color: #fff;
	background-color: #81286D
}

.badge-previsao-retorno[href]:hover,
.badge-previsao-retorno[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #5a1c4c
}

.badge-aniversario {
	color: #fff;
	background-color: #E2A826
}

.badge-aniversario[href]:hover,
.badge-aniversario[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #bc8a19
}

.badge-lima {
	color: #212529;
	background-color: #EFFF33
}

.badge-lima[href]:hover,
.badge-lima[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #ebff00
}

.badge-azul-ceu {
	color: #fff;
	background-color: #5FC0DD
}

.badge-azul-ceu[href]:hover,
.badge-azul-ceu[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #35afd4
}

.badge-videoconferencia {
	color: #fff;
	background-color: #8132b1
}

.badge-videoconferencia[href]:hover,
.badge-videoconferencia[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #642789
}

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: .3rem
}

@media(min-width:576px) {
	.jumbotron {
		padding: 4rem 2rem
	}
}

.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0
}

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem
}

.alert-heading {
	color: inherit
}

.alert-link {
	font-weight: 500
}

.alert-dismissible {
	padding-right: 4rem
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: .75rem 1.25rem;
	color: inherit
}

.alert-primary {
	color: #144f7a;
	background-color: #d4eafb;
	border-color: #c3e2f9
}

.alert-primary hr {
	border-top-color: #acd7f7
}

.alert-primary .alert-link {
	color: #0d334e
}

.alert-secondary {
	color: #404549;
	background-color: #e5e7e8;
	border-color: #dadddf
}

.alert-secondary hr {
	border-top-color: #ccd0d3
}

.alert-secondary .alert-link {
	color: #282b2e
}

.alert-success {
	color: #3e6f00;
	background-color: #e4f7cc;
	border-color: #d9f4b8
}

.alert-success hr {
	border-top-color: #cdf1a2
}

.alert-success .alert-link {
	color: #223c00
}

.alert-info {
	color: #1b6265;
	background-color: #d6f2f3;
	border-color: #c6ecee
}

.alert-info hr {
	border-top-color: #b2e5e8
}

.alert-info .alert-link {
	color: #103b3d
}

.alert-warning {
	color: #7c5c00;
	background-color: #fcefcc;
	border-color: #fbe9b8
}

.alert-warning hr {
	border-top-color: #fae2a0
}

.alert-warning .alert-link {
	color: #493600
}

.alert-danger {
	color: #854843;
	background-color: #ffe8e6;
	border-color: #ffdedb
}

.alert-danger hr {
	border-top-color: #ffc7c2
}

.alert-danger .alert-link {
	color: #633632
}

.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe
}

.alert-light hr {
	border-top-color: #ececf6
}

.alert-light .alert-link {
	color: #686868
}

.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca
}

.alert-dark hr {
	border-top-color: #b9bbbe
}

.alert-dark .alert-link {
	color: #040505
}

.alert-dietbox {
	color: #376700;
	background-color: #e1f4cc;
	border-color: #d5efb8
}

.alert-dietbox hr {
	border-top-color: #c9eaa3
}

.alert-dietbox .alert-link {
	color: #1c3400
}

.alert-dietbox-secondary {
	color: #302a4b;
	background-color: #dedce9;
	border-color: #d1cee0
}

.alert-dietbox-secondary hr {
	border-top-color: #c2bed6
}

.alert-dietbox-secondary .alert-link {
	color: #1b182a
}

.alert-dietbox-app {
	color: #7e5f03;
	background-color: #fcf1cd;
	border-color: #fbebb9
}

.alert-dietbox-app hr {
	border-top-color: #fae4a1
}

.alert-dietbox-app .alert-link {
	color: #4c3902
}

.alert-pro {
	color: #857300;
	background-color: #fff8cc;
	border-color: #fff5b8
}

.alert-pro hr {
	border-top-color: #fff19f
}

.alert-pro .alert-link {
	color: #524700
}

.alert-recebafacil {
	color: #314149;
	background-color: #dfe5e8;
	border-color: #d2dbdf
}

.alert-recebafacil hr {
	border-top-color: #c3cfd4
}

.alert-recebafacil .alert-link {
	color: #1d262a
}

.alert-qpc {
	color: #6d224f;
	background-color: #f6d9ea;
	border-color: #f2cae2
}

.alert-qpc hr {
	border-top-color: #edb6d7
}

.alert-qpc .alert-link {
	color: #461633
}

.alert-anamnese {
	color: #7f382e;
	background-color: #fde1de;
	border-color: #fcd6d1
}

.alert-anamnese hr {
	border-top-color: #fac1b9
}

.alert-anamnese .alert-link {
	color: #5a2720
}

.alert-antropometria {
	color: #4b2662;
	background-color: #e9dbf2;
	border-color: #e0cced
}

.alert-antropometria hr {
	border-top-color: #d5b9e6
}

.alert-antropometria .alert-link {
	color: #2f183d
}

.alert-plano-alimentar {
	color: #325f1d;
	background-color: #dff1d7;
	border-color: #d2ebc7
}

.alert-plano-alimentar hr {
	border-top-color: #c3e4b4
}

.alert-plano-alimentar .alert-link {
	color: #1d3811
}

.alert-plano-alimentar-light {
	color: #697672;
	background-color: #f4f9f8;
	border-color: #f0f7f5
}

.alert-plano-alimentar-light hr {
	border-top-color: #dfeeea
}

.alert-plano-alimentar-light .alert-link {
	color: #515b58
}

.alert-recordatorio {
	color: #825b1b;
	background-color: #feefd6;
	border-color: #fee9c6
}

.alert-recordatorio hr {
	border-top-color: #fedfad
}

.alert-recordatorio .alert-link {
	color: #583d12
}

.alert-gasto-energetico {
	color: #14566f;
	background-color: #d4edf7;
	border-color: #c2e6f3
}

.alert-gasto-energetico hr {
	border-top-color: #adddef
}

.alert-gasto-energetico .alert-link {
	color: #0c3544
}

.alert-avaliacao-laboratorial {
	color: #1b3f42;
	background-color: #d6e4e5;
	border-color: #c6d9db
}

.alert-avaliacao-laboratorial hr {
	border-top-color: #b6cfd1
}

.alert-avaliacao-laboratorial .alert-link {
	color: #0c1c1e
}

.alert-prescricao {
	color: #1d2845;
	background-color: #d7dbe6;
	border-color: #c7cddd
}

.alert-prescricao hr {
	border-top-color: #b7bfd3
}

.alert-prescricao .alert-link {
	color: #0e1321
}

.alert-consulta {
	color: #144f7a;
	background-color: #d4eafb;
	border-color: #c3e2f9
}

.alert-consulta hr {
	border-top-color: #acd7f7
}

.alert-consulta .alert-link {
	color: #0d334e
}

.alert-retorno {
	color: #004f47;
	background-color: #cceae7;
	border-color: #b8e2de
}

.alert-retorno hr {
	border-top-color: #a6dbd6
}

.alert-retorno .alert-link {
	color: #001c19
}

.alert-previsao-retorno {
	color: #431539;
	background-color: #e6d4e2;
	border-color: #dcc3d6
}

.alert-previsao-retorno hr {
	border-top-color: #d3b3cb
}

.alert-previsao-retorno .alert-link {
	color: #1c0918
}

.alert-aniversario {
	color: #765714;
	background-color: #f9eed4;
	border-color: #f7e7c2
}

.alert-aniversario hr {
	border-top-color: #f4deab
}

.alert-aniversario .alert-link {
	color: #4a370d
}

.alert-lima {
	color: #7c851b;
	background-color: #fcffd6;
	border-color: #fbffc6
}

.alert-lima hr {
	border-top-color: #f9ffad
}

.alert-lima .alert-link {
	color: #545b12
}

.alert-azul-ceu {
	color: #316473;
	background-color: #dff2f8;
	border-color: #d2edf5
}

.alert-azul-ceu hr {
	border-top-color: #bde5f0
}

.alert-azul-ceu .alert-link {
	color: #22454f
}

.alert-videoconferencia {
	color: #431a5c;
	background-color: #e6d6ef;
	border-color: #dcc6e9
}

.alert-videoconferencia hr {
	border-top-color: #d1b4e2
}

.alert-videoconferencia .alert-link {
	color: #260f34
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0
	}
	to {
		background-position: 0 0
	}
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: .75rem;
	background-color: #e9ecef;
	border-radius: .25rem
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #2798ea;
	transition: width .6s ease
}

@media screen and (prefers-reduced-motion:reduce) {
	.progress-bar {
		transition: none
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem
}

.progress-bar-animated {
	animation: progress-bar-stripes 1s linear infinite
}

.media {
	display: flex;
	align-items: flex-start
}

.media-body {
	flex: 1
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa
}

.list-group-item-action:active {
	color: #495057;
	background-color: #e9ecef
}

.list-group-item {
	position: relative;
	display: block;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125)
}

.list-group-item:first-child {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.list-group-item:hover,
.list-group-item:focus {
	z-index: 1;
	text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #7c858d;
	background-color: #fff
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #2798ea;
	border-color: #2798ea
}

.list-group-flush .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
	border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
	border-bottom: 0
}

.list-group-item-primary {
	color: #144f7a;
	background-color: #c3e2f9
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #144f7a;
	background-color: #acd7f7
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #144f7a;
	border-color: #144f7a
}

.list-group-item-secondary {
	color: #404549;
	background-color: #dadddf
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #404549;
	background-color: #ccd0d3
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #404549;
	border-color: #404549
}

.list-group-item-success {
	color: #3e6f00;
	background-color: #d9f4b8
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #3e6f00;
	background-color: #cdf1a2
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #3e6f00;
	border-color: #3e6f00
}

.list-group-item-info {
	color: #1b6265;
	background-color: #c6ecee
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: #1b6265;
	background-color: #b2e5e8
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #1b6265;
	border-color: #1b6265
}

.list-group-item-warning {
	color: #7c5c00;
	background-color: #fbe9b8
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: #7c5c00;
	background-color: #fae2a0
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #7c5c00;
	border-color: #7c5c00
}

.list-group-item-danger {
	color: #854843;
	background-color: #ffdedb
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #854843;
	background-color: #ffc7c2
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #854843;
	border-color: #854843
}

.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #818182;
	background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #818182;
	border-color: #818182
}

.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #1b1e21;
	background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #1b1e21;
	border-color: #1b1e21
}

.list-group-item-dietbox {
	color: #376700;
	background-color: #d5efb8
}

.list-group-item-dietbox.list-group-item-action:hover,
.list-group-item-dietbox.list-group-item-action:focus {
	color: #376700;
	background-color: #c9eaa3
}

.list-group-item-dietbox.list-group-item-action.active {
	color: #fff;
	background-color: #376700;
	border-color: #376700
}

.list-group-item-dietbox-secondary {
	color: #302a4b;
	background-color: #d1cee0
}

.list-group-item-dietbox-secondary.list-group-item-action:hover,
.list-group-item-dietbox-secondary.list-group-item-action:focus {
	color: #302a4b;
	background-color: #c2bed6
}

.list-group-item-dietbox-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #302a4b;
	border-color: #302a4b
}

.list-group-item-dietbox-app {
	color: #7e5f03;
	background-color: #fbebb9
}

.list-group-item-dietbox-app.list-group-item-action:hover,
.list-group-item-dietbox-app.list-group-item-action:focus {
	color: #7e5f03;
	background-color: #fae4a1
}

.list-group-item-dietbox-app.list-group-item-action.active {
	color: #fff;
	background-color: #7e5f03;
	border-color: #7e5f03
}

.list-group-item-pro {
	color: #857300;
	background-color: #fff5b8
}

.list-group-item-pro.list-group-item-action:hover,
.list-group-item-pro.list-group-item-action:focus {
	color: #857300;
	background-color: #fff19f
}

.list-group-item-pro.list-group-item-action.active {
	color: #fff;
	background-color: #857300;
	border-color: #857300
}

.list-group-item-recebafacil {
	color: #314149;
	background-color: #d2dbdf
}

.list-group-item-recebafacil.list-group-item-action:hover,
.list-group-item-recebafacil.list-group-item-action:focus {
	color: #314149;
	background-color: #c3cfd4
}

.list-group-item-recebafacil.list-group-item-action.active {
	color: #fff;
	background-color: #314149;
	border-color: #314149
}

.list-group-item-qpc {
	color: #6d224f;
	background-color: #f2cae2
}

.list-group-item-qpc.list-group-item-action:hover,
.list-group-item-qpc.list-group-item-action:focus {
	color: #6d224f;
	background-color: #edb6d7
}

.list-group-item-qpc.list-group-item-action.active {
	color: #fff;
	background-color: #6d224f;
	border-color: #6d224f
}

.list-group-item-anamnese {
	color: #7f382e;
	background-color: #fcd6d1
}

.list-group-item-anamnese.list-group-item-action:hover,
.list-group-item-anamnese.list-group-item-action:focus {
	color: #7f382e;
	background-color: #fac1b9
}

.list-group-item-anamnese.list-group-item-action.active {
	color: #fff;
	background-color: #7f382e;
	border-color: #7f382e
}

.list-group-item-antropometria {
	color: #4b2662;
	background-color: #e0cced
}

.list-group-item-antropometria.list-group-item-action:hover,
.list-group-item-antropometria.list-group-item-action:focus {
	color: #4b2662;
	background-color: #d5b9e6
}

.list-group-item-antropometria.list-group-item-action.active {
	color: #fff;
	background-color: #4b2662;
	border-color: #4b2662
}

.list-group-item-plano-alimentar {
	color: #325f1d;
	background-color: #d2ebc7
}

.list-group-item-plano-alimentar.list-group-item-action:hover,
.list-group-item-plano-alimentar.list-group-item-action:focus {
	color: #325f1d;
	background-color: #c3e4b4
}

.list-group-item-plano-alimentar.list-group-item-action.active {
	color: #fff;
	background-color: #325f1d;
	border-color: #325f1d
}

.list-group-item-plano-alimentar-light {
	color: #697672;
	background-color: #f0f7f5
}

.list-group-item-plano-alimentar-light.list-group-item-action:hover,
.list-group-item-plano-alimentar-light.list-group-item-action:focus {
	color: #697672;
	background-color: #dfeeea
}

.list-group-item-plano-alimentar-light.list-group-item-action.active {
	color: #fff;
	background-color: #697672;
	border-color: #697672
}

.list-group-item-recordatorio {
	color: #825b1b;
	background-color: #fee9c6
}

.list-group-item-recordatorio.list-group-item-action:hover,
.list-group-item-recordatorio.list-group-item-action:focus {
	color: #825b1b;
	background-color: #fedfad
}

.list-group-item-recordatorio.list-group-item-action.active {
	color: #fff;
	background-color: #825b1b;
	border-color: #825b1b
}

.list-group-item-gasto-energetico {
	color: #14566f;
	background-color: #c2e6f3
}

.list-group-item-gasto-energetico.list-group-item-action:hover,
.list-group-item-gasto-energetico.list-group-item-action:focus {
	color: #14566f;
	background-color: #adddef
}

.list-group-item-gasto-energetico.list-group-item-action.active {
	color: #fff;
	background-color: #14566f;
	border-color: #14566f
}

.list-group-item-avaliacao-laboratorial {
	color: #1b3f42;
	background-color: #c6d9db
}

.list-group-item-avaliacao-laboratorial.list-group-item-action:hover,
.list-group-item-avaliacao-laboratorial.list-group-item-action:focus {
	color: #1b3f42;
	background-color: #b6cfd1
}

.list-group-item-avaliacao-laboratorial.list-group-item-action.active {
	color: #fff;
	background-color: #1b3f42;
	border-color: #1b3f42
}

.list-group-item-prescricao {
	color: #1d2845;
	background-color: #c7cddd
}

.list-group-item-prescricao.list-group-item-action:hover,
.list-group-item-prescricao.list-group-item-action:focus {
	color: #1d2845;
	background-color: #b7bfd3
}

.list-group-item-prescricao.list-group-item-action.active {
	color: #fff;
	background-color: #1d2845;
	border-color: #1d2845
}

.list-group-item-consulta {
	color: #144f7a;
	background-color: #c3e2f9
}

.list-group-item-consulta.list-group-item-action:hover,
.list-group-item-consulta.list-group-item-action:focus {
	color: #144f7a;
	background-color: #acd7f7
}

.list-group-item-consulta.list-group-item-action.active {
	color: #fff;
	background-color: #144f7a;
	border-color: #144f7a
}

.list-group-item-retorno {
	color: #004f47;
	background-color: #b8e2de
}

.list-group-item-retorno.list-group-item-action:hover,
.list-group-item-retorno.list-group-item-action:focus {
	color: #004f47;
	background-color: #a6dbd6
}

.list-group-item-retorno.list-group-item-action.active {
	color: #fff;
	background-color: #004f47;
	border-color: #004f47
}

.list-group-item-previsao-retorno {
	color: #431539;
	background-color: #dcc3d6
}

.list-group-item-previsao-retorno.list-group-item-action:hover,
.list-group-item-previsao-retorno.list-group-item-action:focus {
	color: #431539;
	background-color: #d3b3cb
}

.list-group-item-previsao-retorno.list-group-item-action.active {
	color: #fff;
	background-color: #431539;
	border-color: #431539
}

.list-group-item-aniversario {
	color: #765714;
	background-color: #f7e7c2
}

.list-group-item-aniversario.list-group-item-action:hover,
.list-group-item-aniversario.list-group-item-action:focus {
	color: #765714;
	background-color: #f4deab
}

.list-group-item-aniversario.list-group-item-action.active {
	color: #fff;
	background-color: #765714;
	border-color: #765714
}

.list-group-item-lima {
	color: #7c851b;
	background-color: #fbffc6
}

.list-group-item-lima.list-group-item-action:hover,
.list-group-item-lima.list-group-item-action:focus {
	color: #7c851b;
	background-color: #f9ffad
}

.list-group-item-lima.list-group-item-action.active {
	color: #fff;
	background-color: #7c851b;
	border-color: #7c851b
}

.list-group-item-azul-ceu {
	color: #316473;
	background-color: #d2edf5
}

.list-group-item-azul-ceu.list-group-item-action:hover,
.list-group-item-azul-ceu.list-group-item-action:focus {
	color: #316473;
	background-color: #bde5f0
}

.list-group-item-azul-ceu.list-group-item-action.active {
	color: #fff;
	background-color: #316473;
	border-color: #316473
}

.list-group-item-videoconferencia {
	color: #431a5c;
	background-color: #dcc6e9
}

.list-group-item-videoconferencia.list-group-item-action:hover,
.list-group-item-videoconferencia.list-group-item-action:focus {
	color: #431a5c;
	background-color: #d1b4e2
}

.list-group-item-videoconferencia.list-group-item-action.active {
	color: #fff;
	background-color: #431a5c;
	border-color: #431a5c
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5
}

.close:not(:disabled):not(.disabled) {
	cursor: pointer
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	color: #000;
	text-decoration: none;
	opacity: .75
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none
}

.modal-open {
	overflow: hidden
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	outline: 0
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: .5rem;
	pointer-events: none
}

.modal.fade .modal-dialog {
	transition: transform .3s ease-out;
	transform: translate(0, -25%)
}

@media screen and (prefers-reduced-motion:reduce) {
	.modal.fade .modal-dialog {
		transition: none
	}
}

.modal.show .modal-dialog {
	transform: translate(0, 0)
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - (0.5rem * 2))
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: .3rem;
	outline: 0
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000
}

.modal-backdrop.fade {
	opacity: 0
}

.modal-backdrop.show {
	opacity: .5
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid #e9ecef;
	border-top-left-radius: .3rem;
	border-top-right-radius: .3rem
}

.modal-header .close {
	padding: 1rem;
	margin: -1rem -1rem -1rem auto
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem
}

.modal-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #e9ecef
}

.modal-footer>:not(:first-child) {
	margin-left: .25rem
}

.modal-footer>:not(:last-child) {
	margin-right: .25rem
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll
}

@media(min-width:576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto
	}
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2))
	}
	.modal-sm {
		max-width: 300px
	}
}

@media(min-width:992px) {
	.modal-lg {
		max-width: 800px
	}
}

.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	opacity: 0
}

.tooltip.show {
	opacity: .9
}

.tooltip .arrow {
	position: absolute;
	display: block;
	width: .8rem;
	height: .4rem
}

.tooltip .arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
	padding: .4rem 0
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
	bottom: 0
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
	top: 0;
	border-width: .4rem .4rem 0;
	border-top-color: #000
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
	padding: 0 .4rem
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
	left: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
	right: 0;
	border-width: .4rem .4rem .4rem 0;
	border-right-color: #000
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
	padding: .4rem 0
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
	top: 0
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	bottom: 0;
	border-width: 0 .4rem .4rem;
	border-bottom-color: #000
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
	padding: 0 .4rem
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
	right: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
	left: 0;
	border-width: .4rem 0 .4rem .4rem;
	border-left-color: #000
}

.tooltip-inner {
	max-width: 200px;
	padding: .25rem .5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: .25rem
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: .3rem
}

.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: .5rem;
	margin: 0 .3rem
}

.popover .arrow::before,
.popover .arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
	margin-bottom: .5rem
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
	bottom: calc((0.5rem + 1px) * -1)
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	border-width: .5rem .5rem 0
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
	bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	bottom: 1px;
	border-top-color: #fff
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
	margin-left: .5rem
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
	left: calc((0.5rem + 1px) * -1);
	width: .5rem;
	height: 1rem;
	margin: .3rem 0
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	border-width: .5rem .5rem .5rem 0
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
	left: 0;
	border-right-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	left: 1px;
	border-right-color: #fff
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
	margin-top: .5rem
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
	top: calc((0.5rem + 1px) * -1)
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-width: 0 .5rem .5rem .5rem
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	top: 0;
	border-bottom-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	top: 1px;
	border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f7f7f7
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
	margin-right: .5rem
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
	right: calc((0.5rem + 1px) * -1);
	width: .5rem;
	height: 1rem;
	margin: .3rem 0
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	border-width: .5rem 0 .5rem .5rem
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
	right: 0;
	border-left-color: rgba(0, 0, 0, 0.25)
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	right: 1px;
	border-left-color: #fff
}

.popover-header {
	padding: .5rem .75rem;
	margin-bottom: 0;
	font-size: 1rem;
	color: inherit;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px)
}

.popover-header:empty {
	display: none
}

.popover-body {
	padding: .5rem .75rem;
	color: #495057
}

.carousel {
	position: relative
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden
}

.carousel-item {
	position: relative;
	display: none;
	align-items: center;
	width: 100%;
	backface-visibility: hidden;
	perspective: 1000px
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
	transition: transform .6s ease
}

@media screen and (prefers-reduced-motion:reduce) {
	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		transition: none
	}
}

.carousel-item-next,
.carousel-item-prev {
	position: absolute;
	top: 0
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
	transform: translateX(0)
}

@supports(transform-style:preserve-3d) {
	.carousel-item-next.carousel-item-left,
	.carousel-item-prev.carousel-item-right {
		transform: translate3d(0, 0, 0)
	}
}

.carousel-item-next,
.active.carousel-item-right {
	transform: translateX(100%)
}

@supports(transform-style:preserve-3d) {
	.carousel-item-next,
	.active.carousel-item-right {
		transform: translate3d(100%, 0, 0)
	}
}

.carousel-item-prev,
.active.carousel-item-left {
	transform: translateX(-100%)
}

@supports(transform-style:preserve-3d) {
	.carousel-item-prev,
	.active.carousel-item-left {
		transform: translate3d(-100%, 0, 0)
	}
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-duration: .6s;
	transition-property: opacity
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	transform: translateX(0)
}

@supports(transform-style:preserve-3d) {
	.carousel-fade .carousel-item-next,
	.carousel-fade .carousel-item-prev,
	.carousel-fade .carousel-item.active,
	.carousel-fade .active.carousel-item-left,
	.carousel-fade .active.carousel-item-prev {
		transform: translate3d(0, 0, 0)
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	color: #fff;
	text-align: center;
	opacity: .5
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: .9
}

.carousel-control-prev {
	left: 0
}

.carousel-control-next {
	right: 0
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: transparent no-repeat center center;
	background-size: 100% 100%
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none
}

.carousel-indicators li {
	position: relative;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.5)
}

.carousel-indicators li::before {
	position: absolute;
	top: -10px;
	left: 0;
	display: inline-block;
	width: 100%;
	height: 10px;
	content: ""
}

.carousel-indicators li::after {
	position: absolute;
	bottom: -10px;
	left: 0;
	display: inline-block;
	width: 100%;
	height: 10px;
	content: ""
}

.carousel-indicators .active {
	background-color: #fff
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 20px;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center
}

.align-baseline {
	vertical-align: baseline!important
}

.align-top {
	vertical-align: top!important
}

.align-middle {
	vertical-align: middle!important
}

.align-bottom {
	vertical-align: bottom!important
}

.align-text-bottom {
	vertical-align: text-bottom!important
}

.align-text-top {
	vertical-align: text-top!important
}

.bg-primary {
	background-color: #2798ea!important
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
	background-color: #147eca!important
}

.bg-secondary {
	background-color: #7c858d!important
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
	background-color: #646b72!important
}

.bg-success {
	background-color: #77d600!important
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
	background-color: #5ba300!important
}

.bg-info {
	background-color: #33bCc3!important
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
	background-color: #28959b!important
}

.bg-warning {
	background-color: #efb100!important
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
	background-color: #bc8b00!important
}

.bg-danger {
	background-color: #FF8A80!important
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
	background-color: #ff5b4d!important
}

.bg-light {
	background-color: #f8f9fa!important
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
	background-color: #dae0e5!important
}

.bg-dark {
	background-color: #343a40!important
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
	background-color: #1d2124!important
}

.bg-dietbox {
	background-color: #69C600!important
}

a.bg-dietbox:hover,
a.bg-dietbox:focus,
button.bg-dietbox:hover,
button.bg-dietbox:focus {
	background-color: #4e9300!important
}

.bg-dietbox-secondary {
	background-color: #5C5091!important
}

a.bg-dietbox-secondary:hover,
a.bg-dietbox-secondary:focus,
button.bg-dietbox-secondary:hover,
button.bg-dietbox-secondary:focus {
	background-color: #473e70!important
}

.bg-dietbox-app {
	background-color: #F2B705!important
}

a.bg-dietbox-app:hover,
a.bg-dietbox-app:focus,
button.bg-dietbox-app:hover,
button.bg-dietbox-app:focus {
	background-color: #c09104!important
}

.bg-pro {
	background-color: #FD0!important
}

a.bg-pro:hover,
a.bg-pro:focus,
button.bg-pro:hover,
button.bg-pro:focus {
	background-color: #ccb100!important
}

.bg-recebafacil {
	background-color: #5F7D8C!important
}

a.bg-recebafacil:hover,
a.bg-recebafacil:focus,
button.bg-recebafacil:hover,
button.bg-recebafacil:focus {
	background-color: #4a626e!important
}

.bg-qpc {
	background-color: #d24297!important
}

a.bg-qpc:hover,
a.bg-qpc:focus,
button.bg-qpc:hover,
button.bg-qpc:focus {
	background-color: #b62b7d!important
}

.bg-anamnese {
	background-color: #F46B59!important
}

a.bg-anamnese:hover,
a.bg-anamnese:focus,
button.bg-anamnese:hover,
button.bg-anamnese:focus {
	background-color: #f14029!important
}

.bg-antropometria {
	background-color: #9149BD!important
}

a.bg-antropometria:hover,
a.bg-antropometria:focus,
button.bg-antropometria:hover,
button.bg-antropometria:focus {
	background-color: #75389b!important
}

.bg-plano-alimentar {
	background-color: #60B738!important
}

a.bg-plano-alimentar:hover,
a.bg-plano-alimentar:focus,
button.bg-plano-alimentar:hover,
button.bg-plano-alimentar:focus {
	background-color: #4c902c!important
}

.bg-plano-alimentar-light {
	background-color: #C9E3DB!important
}

a.bg-plano-alimentar-light:hover,
a.bg-plano-alimentar-light:focus,
button.bg-plano-alimentar-light:hover,
button.bg-plano-alimentar-light:focus {
	background-color: #a7d2c5!important
}

.bg-recordatorio {
	background-color: #FAAF33!important
}

a.bg-recordatorio:hover,
a.bg-recordatorio:focus,
button.bg-recordatorio:hover,
button.bg-recordatorio:focus {
	background-color: #f49a06!important
}

.bg-gasto-energetico {
	background-color: #26A6D5!important
}

a.bg-gasto-energetico:hover,
a.bg-gasto-energetico:focus,
button.bg-gasto-energetico:hover,
button.bg-gasto-energetico:focus {
	background-color: #1e84aa!important
}

.bg-avaliacao-laboratorial {
	background-color: #33797F!important
}

a.bg-avaliacao-laboratorial:hover,
a.bg-avaliacao-laboratorial:focus,
button.bg-avaliacao-laboratorial:hover,
button.bg-avaliacao-laboratorial:focus {
	background-color: #24565b!important
}

.bg-prescricao {
	background-color: #384C84!important
}

a.bg-prescricao:hover,
a.bg-prescricao:focus,
button.bg-prescricao:hover,
button.bg-prescricao:focus {
	background-color: #293760!important
}

.bg-consulta {
	background-color: #2798ea!important
}

a.bg-consulta:hover,
a.bg-consulta:focus,
button.bg-consulta:hover,
button.bg-consulta:focus {
	background-color: #147eca!important
}

.bg-retorno {
	background-color: #009788!important
}

a.bg-retorno:hover,
a.bg-retorno:focus,
button.bg-retorno:hover,
button.bg-retorno:focus {
	background-color: #00645a!important
}

.bg-previsao-retorno {
	background-color: #81286D!important
}

a.bg-previsao-retorno:hover,
a.bg-previsao-retorno:focus,
button.bg-previsao-retorno:hover,
button.bg-previsao-retorno:focus {
	background-color: #5a1c4c!important
}

.bg-aniversario {
	background-color: #E2A826!important
}

a.bg-aniversario:hover,
a.bg-aniversario:focus,
button.bg-aniversario:hover,
button.bg-aniversario:focus {
	background-color: #bc8a19!important
}

.bg-lima {
	background-color: #EFFF33!important
}

a.bg-lima:hover,
a.bg-lima:focus,
button.bg-lima:hover,
button.bg-lima:focus {
	background-color: #ebff00!important
}

.bg-azul-ceu {
	background-color: #5FC0DD!important
}

a.bg-azul-ceu:hover,
a.bg-azul-ceu:focus,
button.bg-azul-ceu:hover,
button.bg-azul-ceu:focus {
	background-color: #35afd4!important
}

.bg-videoconferencia {
	background-color: #8132b1!important
}

a.bg-videoconferencia:hover,
a.bg-videoconferencia:focus,
button.bg-videoconferencia:hover,
button.bg-videoconferencia:focus {
	background-color: #642789!important
}

.bg-white {
	background-color: #fff!important
}

.bg-transparent {
	background-color: transparent!important
}

.border {
	border: 1px solid #dee2e6!important
}

.border-top {
	border-top: 1px solid #dee2e6!important
}

.border-right {
	border-right: 1px solid #dee2e6!important
}

.border-bottom {
	border-bottom: 1px solid #dee2e6!important
}

.border-left {
	border-left: 1px solid #dee2e6!important
}

.border-0 {
	border: 0!important
}

.border-top-0 {
	border-top: 0!important
}

.border-right-0 {
	border-right: 0!important
}

.border-bottom-0 {
	border-bottom: 0!important
}

.border-left-0 {
	border-left: 0!important
}

.border-primary {
	border-color: #2798ea!important
}

.border-secondary {
	border-color: #7c858d!important
}

.border-success {
	border-color: #77d600!important
}

.border-info {
	border-color: #33bCc3!important
}

.border-warning {
	border-color: #efb100!important
}

.border-danger {
	border-color: #FF8A80!important
}

.border-light {
	border-color: #f8f9fa!important
}

.border-dark {
	border-color: #343a40!important
}

.border-dietbox {
	border-color: #69C600!important
}

.border-dietbox-secondary {
	border-color: #5C5091!important
}

.border-dietbox-app {
	border-color: #F2B705!important
}

.border-pro {
	border-color: #FD0!important
}

.border-recebafacil {
	border-color: #5F7D8C!important
}

.border-qpc {
	border-color: #d24297!important
}

.border-anamnese {
	border-color: #F46B59!important
}

.border-antropometria {
	border-color: #9149BD!important
}

.border-plano-alimentar {
	border-color: #60B738!important
}

.border-plano-alimentar-light {
	border-color: #C9E3DB!important
}

.border-recordatorio {
	border-color: #FAAF33!important
}

.border-gasto-energetico {
	border-color: #26A6D5!important
}

.border-avaliacao-laboratorial {
	border-color: #33797F!important
}

.border-prescricao {
	border-color: #384C84!important
}

.border-consulta {
	border-color: #2798ea!important
}

.border-retorno {
	border-color: #009788!important
}

.border-previsao-retorno {
	border-color: #81286D!important
}

.border-aniversario {
	border-color: #E2A826!important
}

.border-lima {
	border-color: #EFFF33!important
}

.border-azul-ceu {
	border-color: #5FC0DD!important
}

.border-videoconferencia {
	border-color: #8132b1!important
}

.border-white {
	border-color: #fff!important
}

.rounded {
	border-radius: .25rem!important
}

.rounded-top {
	border-top-left-radius: .25rem!important;
	border-top-right-radius: .25rem!important
}

.rounded-right {
	border-top-right-radius: .25rem!important;
	border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
	border-bottom-right-radius: .25rem!important;
	border-bottom-left-radius: .25rem!important
}

.rounded-left {
	border-top-left-radius: .25rem!important;
	border-bottom-left-radius: .25rem!important
}

.rounded-circle {
	border-radius: 50%!important
}

.rounded-0 {
	border-radius: 0!important
}

.clearfix::after {
	display: block;
	clear: both;
	content: ""
}

.d-none {
	display: none!important
}

.d-inline {
	display: inline!important
}

.d-inline-block {
	display: inline-block!important
}

.d-block {
	display: block!important
}

.d-table {
	display: table!important
}

.d-table-row {
	display: table-row!important
}

.d-table-cell {
	display: table-cell!important
}

.d-flex {
	display: flex!important
}

.d-inline-flex {
	display: inline-flex!important
}

@media(min-width:576px) {
	.d-sm-none {
		display: none!important
	}
	.d-sm-inline {
		display: inline!important
	}
	.d-sm-inline-block {
		display: inline-block!important
	}
	.d-sm-block {
		display: block!important
	}
	.d-sm-table {
		display: table!important
	}
	.d-sm-table-row {
		display: table-row!important
	}
	.d-sm-table-cell {
		display: table-cell!important
	}
	.d-sm-flex {
		display: flex!important
	}
	.d-sm-inline-flex {
		display: inline-flex!important
	}
}

@media(min-width:768px) {
	.d-md-none {
		display: none!important
	}
	.d-md-inline {
		display: inline!important
	}
	.d-md-inline-block {
		display: inline-block!important
	}
	.d-md-block {
		display: block!important
	}
	.d-md-table {
		display: table!important
	}
	.d-md-table-row {
		display: table-row!important
	}
	.d-md-table-cell {
		display: table-cell!important
	}
	.d-md-flex {
		display: flex!important
	}
	.d-md-inline-flex {
		display: inline-flex!important
	}
}

@media(min-width:992px) {
	.d-lg-none {
		display: none!important
	}
	.d-lg-inline {
		display: inline!important
	}
	.d-lg-inline-block {
		display: inline-block!important
	}
	.d-lg-block {
		display: block!important
	}
	.d-lg-table {
		display: table!important
	}
	.d-lg-table-row {
		display: table-row!important
	}
	.d-lg-table-cell {
		display: table-cell!important
	}
	.d-lg-flex {
		display: flex!important
	}
	.d-lg-inline-flex {
		display: inline-flex!important
	}
}

@media(min-width:1200px) {
	.d-xl-none {
		display: none!important
	}
	.d-xl-inline {
		display: inline!important
	}
	.d-xl-inline-block {
		display: inline-block!important
	}
	.d-xl-block {
		display: block!important
	}
	.d-xl-table {
		display: table!important
	}
	.d-xl-table-row {
		display: table-row!important
	}
	.d-xl-table-cell {
		display: table-cell!important
	}
	.d-xl-flex {
		display: flex!important
	}
	.d-xl-inline-flex {
		display: inline-flex!important
	}
}

@media print {
	.d-print-none {
		display: none!important
	}
	.d-print-inline {
		display: inline!important
	}
	.d-print-inline-block {
		display: inline-block!important
	}
	.d-print-block {
		display: block!important
	}
	.d-print-table {
		display: table!important
	}
	.d-print-table-row {
		display: table-row!important
	}
	.d-print-table-cell {
		display: table-cell!important
	}
	.d-print-flex {
		display: flex!important
	}
	.d-print-inline-flex {
		display: inline-flex!important
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden
}

.embed-responsive::before {
	display: block;
	content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0
}

.embed-responsive-21by9::before {
	padding-top: 42.8571428571%
}

.embed-responsive-16by9::before {
	padding-top: 56.25%
}

.embed-responsive-4by3::before {
	padding-top: 75%
}

.embed-responsive-1by1::before {
	padding-top: 100%
}

.flex-row {
	flex-direction: row!important
}

.flex-column {
	flex-direction: column!important
}

.flex-row-reverse {
	flex-direction: row-reverse!important
}

.flex-column-reverse {
	flex-direction: column-reverse!important
}

.flex-wrap {
	flex-wrap: wrap!important
}

.flex-nowrap {
	flex-wrap: nowrap!important
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse!important
}

.flex-fill {
	flex: 1 1 auto!important
}

.flex-grow-0 {
	flex-grow: 0!important
}

.flex-grow-1 {
	flex-grow: 1!important
}

.flex-shrink-0 {
	flex-shrink: 0!important
}

.flex-shrink-1 {
	flex-shrink: 1!important
}

.justify-content-start {
	justify-content: flex-start!important
}

.justify-content-end {
	justify-content: flex-end!important
}

.justify-content-center {
	justify-content: center!important
}

.justify-content-between {
	justify-content: space-between!important
}

.justify-content-around {
	justify-content: space-around!important
}

.align-items-start {
	align-items: flex-start!important
}

.align-items-end {
	align-items: flex-end!important
}

.align-items-center {
	align-items: center!important
}

.align-items-baseline {
	align-items: baseline!important
}

.align-items-stretch {
	align-items: stretch!important
}

.align-content-start {
	align-content: flex-start!important
}

.align-content-end {
	align-content: flex-end!important
}

.align-content-center {
	align-content: center!important
}

.align-content-between {
	align-content: space-between!important
}

.align-content-around {
	align-content: space-around!important
}

.align-content-stretch {
	align-content: stretch!important
}

.align-self-auto {
	align-self: auto!important
}

.align-self-start {
	align-self: flex-start!important
}

.align-self-end {
	align-self: flex-end!important
}

.align-self-center {
	align-self: center!important
}

.align-self-baseline {
	align-self: baseline!important
}

.align-self-stretch {
	align-self: stretch!important
}

@media(min-width:576px) {
	.flex-sm-row {
		flex-direction: row!important
	}
	.flex-sm-column {
		flex-direction: column!important
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse!important
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse!important
	}
	.flex-sm-wrap {
		flex-wrap: wrap!important
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.flex-sm-fill {
		flex: 1 1 auto!important
	}
	.flex-sm-grow-0 {
		flex-grow: 0!important
	}
	.flex-sm-grow-1 {
		flex-grow: 1!important
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1!important
	}
	.justify-content-sm-start {
		justify-content: flex-start!important
	}
	.justify-content-sm-end {
		justify-content: flex-end!important
	}
	.justify-content-sm-center {
		justify-content: center!important
	}
	.justify-content-sm-between {
		justify-content: space-between!important
	}
	.justify-content-sm-around {
		justify-content: space-around!important
	}
	.align-items-sm-start {
		align-items: flex-start!important
	}
	.align-items-sm-end {
		align-items: flex-end!important
	}
	.align-items-sm-center {
		align-items: center!important
	}
	.align-items-sm-baseline {
		align-items: baseline!important
	}
	.align-items-sm-stretch {
		align-items: stretch!important
	}
	.align-content-sm-start {
		align-content: flex-start!important
	}
	.align-content-sm-end {
		align-content: flex-end!important
	}
	.align-content-sm-center {
		align-content: center!important
	}
	.align-content-sm-between {
		align-content: space-between!important
	}
	.align-content-sm-around {
		align-content: space-around!important
	}
	.align-content-sm-stretch {
		align-content: stretch!important
	}
	.align-self-sm-auto {
		align-self: auto!important
	}
	.align-self-sm-start {
		align-self: flex-start!important
	}
	.align-self-sm-end {
		align-self: flex-end!important
	}
	.align-self-sm-center {
		align-self: center!important
	}
	.align-self-sm-baseline {
		align-self: baseline!important
	}
	.align-self-sm-stretch {
		align-self: stretch!important
	}
}

@media(min-width:768px) {
	.flex-md-row {
		flex-direction: row!important
	}
	.flex-md-column {
		flex-direction: column!important
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse!important
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse!important
	}
	.flex-md-wrap {
		flex-wrap: wrap!important
	}
	.flex-md-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.flex-md-fill {
		flex: 1 1 auto!important
	}
	.flex-md-grow-0 {
		flex-grow: 0!important
	}
	.flex-md-grow-1 {
		flex-grow: 1!important
	}
	.flex-md-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-md-shrink-1 {
		flex-shrink: 1!important
	}
	.justify-content-md-start {
		justify-content: flex-start!important
	}
	.justify-content-md-end {
		justify-content: flex-end!important
	}
	.justify-content-md-center {
		justify-content: center!important
	}
	.justify-content-md-between {
		justify-content: space-between!important
	}
	.justify-content-md-around {
		justify-content: space-around!important
	}
	.align-items-md-start {
		align-items: flex-start!important
	}
	.align-items-md-end {
		align-items: flex-end!important
	}
	.align-items-md-center {
		align-items: center!important
	}
	.align-items-md-baseline {
		align-items: baseline!important
	}
	.align-items-md-stretch {
		align-items: stretch!important
	}
	.align-content-md-start {
		align-content: flex-start!important
	}
	.align-content-md-end {
		align-content: flex-end!important
	}
	.align-content-md-center {
		align-content: center!important
	}
	.align-content-md-between {
		align-content: space-between!important
	}
	.align-content-md-around {
		align-content: space-around!important
	}
	.align-content-md-stretch {
		align-content: stretch!important
	}
	.align-self-md-auto {
		align-self: auto!important
	}
	.align-self-md-start {
		align-self: flex-start!important
	}
	.align-self-md-end {
		align-self: flex-end!important
	}
	.align-self-md-center {
		align-self: center!important
	}
	.align-self-md-baseline {
		align-self: baseline!important
	}
	.align-self-md-stretch {
		align-self: stretch!important
	}
}

@media(min-width:992px) {
	.flex-lg-row {
		flex-direction: row!important
	}
	.flex-lg-column {
		flex-direction: column!important
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse!important
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse!important
	}
	.flex-lg-wrap {
		flex-wrap: wrap!important
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.flex-lg-fill {
		flex: 1 1 auto!important
	}
	.flex-lg-grow-0 {
		flex-grow: 0!important
	}
	.flex-lg-grow-1 {
		flex-grow: 1!important
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1!important
	}
	.justify-content-lg-start {
		justify-content: flex-start!important
	}
	.justify-content-lg-end {
		justify-content: flex-end!important
	}
	.justify-content-lg-center {
		justify-content: center!important
	}
	.justify-content-lg-between {
		justify-content: space-between!important
	}
	.justify-content-lg-around {
		justify-content: space-around!important
	}
	.align-items-lg-start {
		align-items: flex-start!important
	}
	.align-items-lg-end {
		align-items: flex-end!important
	}
	.align-items-lg-center {
		align-items: center!important
	}
	.align-items-lg-baseline {
		align-items: baseline!important
	}
	.align-items-lg-stretch {
		align-items: stretch!important
	}
	.align-content-lg-start {
		align-content: flex-start!important
	}
	.align-content-lg-end {
		align-content: flex-end!important
	}
	.align-content-lg-center {
		align-content: center!important
	}
	.align-content-lg-between {
		align-content: space-between!important
	}
	.align-content-lg-around {
		align-content: space-around!important
	}
	.align-content-lg-stretch {
		align-content: stretch!important
	}
	.align-self-lg-auto {
		align-self: auto!important
	}
	.align-self-lg-start {
		align-self: flex-start!important
	}
	.align-self-lg-end {
		align-self: flex-end!important
	}
	.align-self-lg-center {
		align-self: center!important
	}
	.align-self-lg-baseline {
		align-self: baseline!important
	}
	.align-self-lg-stretch {
		align-self: stretch!important
	}
}

@media(min-width:1200px) {
	.flex-xl-row {
		flex-direction: row!important
	}
	.flex-xl-column {
		flex-direction: column!important
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse!important
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse!important
	}
	.flex-xl-wrap {
		flex-wrap: wrap!important
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap!important
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse!important
	}
	.flex-xl-fill {
		flex: 1 1 auto!important
	}
	.flex-xl-grow-0 {
		flex-grow: 0!important
	}
	.flex-xl-grow-1 {
		flex-grow: 1!important
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0!important
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1!important
	}
	.justify-content-xl-start {
		justify-content: flex-start!important
	}
	.justify-content-xl-end {
		justify-content: flex-end!important
	}
	.justify-content-xl-center {
		justify-content: center!important
	}
	.justify-content-xl-between {
		justify-content: space-between!important
	}
	.justify-content-xl-around {
		justify-content: space-around!important
	}
	.align-items-xl-start {
		align-items: flex-start!important
	}
	.align-items-xl-end {
		align-items: flex-end!important
	}
	.align-items-xl-center {
		align-items: center!important
	}
	.align-items-xl-baseline {
		align-items: baseline!important
	}
	.align-items-xl-stretch {
		align-items: stretch!important
	}
	.align-content-xl-start {
		align-content: flex-start!important
	}
	.align-content-xl-end {
		align-content: flex-end!important
	}
	.align-content-xl-center {
		align-content: center!important
	}
	.align-content-xl-between {
		align-content: space-between!important
	}
	.align-content-xl-around {
		align-content: space-around!important
	}
	.align-content-xl-stretch {
		align-content: stretch!important
	}
	.align-self-xl-auto {
		align-self: auto!important
	}
	.align-self-xl-start {
		align-self: flex-start!important
	}
	.align-self-xl-end {
		align-self: flex-end!important
	}
	.align-self-xl-center {
		align-self: center!important
	}
	.align-self-xl-baseline {
		align-self: baseline!important
	}
	.align-self-xl-stretch {
		align-self: stretch!important
	}
}

.float-left {
	float: left!important
}

.float-right {
	float: right!important
}

.float-none {
	float: none!important
}

@media(min-width:576px) {
	.float-sm-left {
		float: left!important
	}
	.float-sm-right {
		float: right!important
	}
	.float-sm-none {
		float: none!important
	}
}

@media(min-width:768px) {
	.float-md-left {
		float: left!important
	}
	.float-md-right {
		float: right!important
	}
	.float-md-none {
		float: none!important
	}
}

@media(min-width:992px) {
	.float-lg-left {
		float: left!important
	}
	.float-lg-right {
		float: right!important
	}
	.float-lg-none {
		float: none!important
	}
}

@media(min-width:1200px) {
	.float-xl-left {
		float: left!important
	}
	.float-xl-right {
		float: right!important
	}
	.float-xl-none {
		float: none!important
	}
}

.position-static {
	position: static!important
}

.position-relative {
	position: relative!important
}

.position-absolute {
	position: absolute!important
}

.position-fixed {
	position: fixed!important
}

.position-sticky {
	position: sticky!important
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030
}

@supports(position:sticky) {
	.sticky-top {
		position: sticky;
		top: 0;
		z-index: 1020
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal
}

.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.075)!important
}

.shadow {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15)!important
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175)!important
}

.shadow-none {
	box-shadow: none!important
}

.w-25 {
	width: 25%!important
}

.w-50 {
	width: 50%!important
}

.w-75 {
	width: 75%!important
}

.w-100 {
	width: 100%!important
}

.w-auto {
	width: auto!important
}

.h-25 {
	height: 25%!important
}

.h-50 {
	height: 50%!important
}

.h-75 {
	height: 75%!important
}

.h-100 {
	height: 100%!important
}

.h-auto {
	height: auto!important
}

.mw-100 {
	max-width: 100%!important
}

.mh-100 {
	max-height: 100%!important
}

.m-0 {
	margin: 0!important
}

.mt-0,
.my-0 {
	margin-top: 0!important
}

.mr-0,
.mx-0 {
	margin-right: 0!important
}

.mb-0,
.my-0 {
	margin-bottom: 0!important
}

.ml-0,
.mx-0 {
	margin-left: 0!important
}

.m-1 {
	margin: .25rem!important
}

.mt-1,
.my-1 {
	margin-top: .25rem!important
}

.mr-1,
.mx-1 {
	margin-right: .25rem!important
}

.mb-1,
.my-1 {
	margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
	margin-left: .25rem!important
}

.m-2 {
	margin: .5rem!important
}

.mt-2,
.my-2 {
	margin-top: .5rem!important
}

.mr-2,
.mx-2 {
	margin-right: .5rem!important
}

.mb-2,
.my-2 {
	margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
	margin-left: .5rem!important
}

.m-3 {
	margin: 1rem!important
}

.mt-3,
.my-3 {
	margin-top: 1rem!important
}

.mr-3,
.mx-3 {
	margin-right: 1rem!important
}

.mb-3,
.my-3 {
	margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
	margin-left: 1rem!important
}

.m-4 {
	margin: 1.5rem!important
}

.mt-4,
.my-4 {
	margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem!important
}

.m-5 {
	margin: 3rem!important
}

.mt-5,
.my-5 {
	margin-top: 3rem!important
}

.mr-5,
.mx-5 {
	margin-right: 3rem!important
}

.mb-5,
.my-5 {
	margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
	margin-left: 3rem!important
}

.p-0 {
	padding: 0!important
}

.pt-0,
.py-0 {
	padding-top: 0!important
}

.pr-0,
.px-0 {
	padding-right: 0!important
}

.pb-0,
.py-0 {
	padding-bottom: 0!important
}

.pl-0,
.px-0 {
	padding-left: 0!important
}

.p-1 {
	padding: .25rem!important
}

.pt-1,
.py-1 {
	padding-top: .25rem!important
}

.pr-1,
.px-1 {
	padding-right: .25rem!important
}

.pb-1,
.py-1 {
	padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
	padding-left: .25rem!important
}

.p-2 {
	padding: .5rem!important
}

.pt-2,
.py-2 {
	padding-top: .5rem!important
}

.pr-2,
.px-2 {
	padding-right: .5rem!important
}

.pb-2,
.py-2 {
	padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
	padding-left: .5rem!important
}

.p-3 {
	padding: 1rem!important
}

.pt-3,
.py-3 {
	padding-top: 1rem!important
}

.pr-3,
.px-3 {
	padding-right: 1rem!important
}

.pb-3,
.py-3 {
	padding-bottom: 1rem!important
}

.pl-3,
.px-3 {
	padding-left: 1rem!important
}

.p-4 {
	padding: 1.5rem!important
}

.pt-4,
.py-4 {
	padding-top: 1.5rem!important
}

.pr-4,
.px-4 {
	padding-right: 1.5rem!important
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem!important
}

.pl-4,
.px-4 {
	padding-left: 1.5rem!important
}

.p-5 {
	padding: 3rem!important
}

.pt-5,
.py-5 {
	padding-top: 3rem!important
}

.pr-5,
.px-5 {
	padding-right: 3rem!important
}

.pb-5,
.py-5 {
	padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
	padding-left: 3rem!important
}

.m-auto {
	margin: auto!important
}

.mt-auto,
.my-auto {
	margin-top: auto!important
}

.mr-auto,
.mx-auto {
	margin-right: auto!important
}

.mb-auto,
.my-auto {
	margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
	margin-left: auto!important
}

@media(min-width:576px) {
	.m-sm-0 {
		margin: 0!important
	}
	.mt-sm-0,
	.my-sm-0 {
		margin-top: 0!important
	}
	.mr-sm-0,
	.mx-sm-0 {
		margin-right: 0!important
	}
	.mb-sm-0,
	.my-sm-0 {
		margin-bottom: 0!important
	}
	.ml-sm-0,
	.mx-sm-0 {
		margin-left: 0!important
	}
	.m-sm-1 {
		margin: .25rem!important
	}
	.mt-sm-1,
	.my-sm-1 {
		margin-top: .25rem!important
	}
	.mr-sm-1,
	.mx-sm-1 {
		margin-right: .25rem!important
	}
	.mb-sm-1,
	.my-sm-1 {
		margin-bottom: .25rem!important
	}
	.ml-sm-1,
	.mx-sm-1 {
		margin-left: .25rem!important
	}
	.m-sm-2 {
		margin: .5rem!important
	}
	.mt-sm-2,
	.my-sm-2 {
		margin-top: .5rem!important
	}
	.mr-sm-2,
	.mx-sm-2 {
		margin-right: .5rem!important
	}
	.mb-sm-2,
	.my-sm-2 {
		margin-bottom: .5rem!important
	}
	.ml-sm-2,
	.mx-sm-2 {
		margin-left: .5rem!important
	}
	.m-sm-3 {
		margin: 1rem!important
	}
	.mt-sm-3,
	.my-sm-3 {
		margin-top: 1rem!important
	}
	.mr-sm-3,
	.mx-sm-3 {
		margin-right: 1rem!important
	}
	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem!important
	}
	.ml-sm-3,
	.mx-sm-3 {
		margin-left: 1rem!important
	}
	.m-sm-4 {
		margin: 1.5rem!important
	}
	.mt-sm-4,
	.my-sm-4 {
		margin-top: 1.5rem!important
	}
	.mr-sm-4,
	.mx-sm-4 {
		margin-right: 1.5rem!important
	}
	.mb-sm-4,
	.my-sm-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-sm-4,
	.mx-sm-4 {
		margin-left: 1.5rem!important
	}
	.m-sm-5 {
		margin: 3rem!important
	}
	.mt-sm-5,
	.my-sm-5 {
		margin-top: 3rem!important
	}
	.mr-sm-5,
	.mx-sm-5 {
		margin-right: 3rem!important
	}
	.mb-sm-5,
	.my-sm-5 {
		margin-bottom: 3rem!important
	}
	.ml-sm-5,
	.mx-sm-5 {
		margin-left: 3rem!important
	}
	.p-sm-0 {
		padding: 0!important
	}
	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0!important
	}
	.pr-sm-0,
	.px-sm-0 {
		padding-right: 0!important
	}
	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0!important
	}
	.pl-sm-0,
	.px-sm-0 {
		padding-left: 0!important
	}
	.p-sm-1 {
		padding: .25rem!important
	}
	.pt-sm-1,
	.py-sm-1 {
		padding-top: .25rem!important
	}
	.pr-sm-1,
	.px-sm-1 {
		padding-right: .25rem!important
	}
	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: .25rem!important
	}
	.pl-sm-1,
	.px-sm-1 {
		padding-left: .25rem!important
	}
	.p-sm-2 {
		padding: .5rem!important
	}
	.pt-sm-2,
	.py-sm-2 {
		padding-top: .5rem!important
	}
	.pr-sm-2,
	.px-sm-2 {
		padding-right: .5rem!important
	}
	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: .5rem!important
	}
	.pl-sm-2,
	.px-sm-2 {
		padding-left: .5rem!important
	}
	.p-sm-3 {
		padding: 1rem!important
	}
	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem!important
	}
	.pr-sm-3,
	.px-sm-3 {
		padding-right: 1rem!important
	}
	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem!important
	}
	.pl-sm-3,
	.px-sm-3 {
		padding-left: 1rem!important
	}
	.p-sm-4 {
		padding: 1.5rem!important
	}
	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem!important
	}
	.pr-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem!important
	}
	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem!important
	}
	.p-sm-5 {
		padding: 3rem!important
	}
	.pt-sm-5,
	.py-sm-5 {
		padding-top: 3rem!important
	}
	.pr-sm-5,
	.px-sm-5 {
		padding-right: 3rem!important
	}
	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 3rem!important
	}
	.pl-sm-5,
	.px-sm-5 {
		padding-left: 3rem!important
	}
	.m-sm-auto {
		margin: auto!important
	}
	.mt-sm-auto,
	.my-sm-auto {
		margin-top: auto!important
	}
	.mr-sm-auto,
	.mx-sm-auto {
		margin-right: auto!important
	}
	.mb-sm-auto,
	.my-sm-auto {
		margin-bottom: auto!important
	}
	.ml-sm-auto,
	.mx-sm-auto {
		margin-left: auto!important
	}
}

@media(min-width:768px) {
	.m-md-0 {
		margin: 0!important
	}
	.mt-md-0,
	.my-md-0 {
		margin-top: 0!important
	}
	.mr-md-0,
	.mx-md-0 {
		margin-right: 0!important
	}
	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0!important
	}
	.ml-md-0,
	.mx-md-0 {
		margin-left: 0!important
	}
	.m-md-1 {
		margin: .25rem!important
	}
	.mt-md-1,
	.my-md-1 {
		margin-top: .25rem!important
	}
	.mr-md-1,
	.mx-md-1 {
		margin-right: .25rem!important
	}
	.mb-md-1,
	.my-md-1 {
		margin-bottom: .25rem!important
	}
	.ml-md-1,
	.mx-md-1 {
		margin-left: .25rem!important
	}
	.m-md-2 {
		margin: .5rem!important
	}
	.mt-md-2,
	.my-md-2 {
		margin-top: .5rem!important
	}
	.mr-md-2,
	.mx-md-2 {
		margin-right: .5rem!important
	}
	.mb-md-2,
	.my-md-2 {
		margin-bottom: .5rem!important
	}
	.ml-md-2,
	.mx-md-2 {
		margin-left: .5rem!important
	}
	.m-md-3 {
		margin: 1rem!important
	}
	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem!important
	}
	.mr-md-3,
	.mx-md-3 {
		margin-right: 1rem!important
	}
	.mb-md-3,
	.my-md-3 {
		margin-bottom: 1rem!important
	}
	.ml-md-3,
	.mx-md-3 {
		margin-left: 1rem!important
	}
	.m-md-4 {
		margin: 1.5rem!important
	}
	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem!important
	}
	.mr-md-4,
	.mx-md-4 {
		margin-right: 1.5rem!important
	}
	.mb-md-4,
	.my-md-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-md-4,
	.mx-md-4 {
		margin-left: 1.5rem!important
	}
	.m-md-5 {
		margin: 3rem!important
	}
	.mt-md-5,
	.my-md-5 {
		margin-top: 3rem!important
	}
	.mr-md-5,
	.mx-md-5 {
		margin-right: 3rem!important
	}
	.mb-md-5,
	.my-md-5 {
		margin-bottom: 3rem!important
	}
	.ml-md-5,
	.mx-md-5 {
		margin-left: 3rem!important
	}
	.p-md-0 {
		padding: 0!important
	}
	.pt-md-0,
	.py-md-0 {
		padding-top: 0!important
	}
	.pr-md-0,
	.px-md-0 {
		padding-right: 0!important
	}
	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0!important
	}
	.pl-md-0,
	.px-md-0 {
		padding-left: 0!important
	}
	.p-md-1 {
		padding: .25rem!important
	}
	.pt-md-1,
	.py-md-1 {
		padding-top: .25rem!important
	}
	.pr-md-1,
	.px-md-1 {
		padding-right: .25rem!important
	}
	.pb-md-1,
	.py-md-1 {
		padding-bottom: .25rem!important
	}
	.pl-md-1,
	.px-md-1 {
		padding-left: .25rem!important
	}
	.p-md-2 {
		padding: .5rem!important
	}
	.pt-md-2,
	.py-md-2 {
		padding-top: .5rem!important
	}
	.pr-md-2,
	.px-md-2 {
		padding-right: .5rem!important
	}
	.pb-md-2,
	.py-md-2 {
		padding-bottom: .5rem!important
	}
	.pl-md-2,
	.px-md-2 {
		padding-left: .5rem!important
	}
	.p-md-3 {
		padding: 1rem!important
	}
	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem!important
	}
	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem!important
	}
	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem!important
	}
	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem!important
	}
	.p-md-4 {
		padding: 1.5rem!important
	}
	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem!important
	}
	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem!important
	}
	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-md-4,
	.px-md-4 {
		padding-left: 1.5rem!important
	}
	.p-md-5 {
		padding: 3rem!important
	}
	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem!important
	}
	.pr-md-5,
	.px-md-5 {
		padding-right: 3rem!important
	}
	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem!important
	}
	.pl-md-5,
	.px-md-5 {
		padding-left: 3rem!important
	}
	.m-md-auto {
		margin: auto!important
	}
	.mt-md-auto,
	.my-md-auto {
		margin-top: auto!important
	}
	.mr-md-auto,
	.mx-md-auto {
		margin-right: auto!important
	}
	.mb-md-auto,
	.my-md-auto {
		margin-bottom: auto!important
	}
	.ml-md-auto,
	.mx-md-auto {
		margin-left: auto!important
	}
}

@media(min-width:992px) {
	.m-lg-0 {
		margin: 0!important
	}
	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0!important
	}
	.mr-lg-0,
	.mx-lg-0 {
		margin-right: 0!important
	}
	.mb-lg-0,
	.my-lg-0 {
		margin-bottom: 0!important
	}
	.ml-lg-0,
	.mx-lg-0 {
		margin-left: 0!important
	}
	.m-lg-1 {
		margin: .25rem!important
	}
	.mt-lg-1,
	.my-lg-1 {
		margin-top: .25rem!important
	}
	.mr-lg-1,
	.mx-lg-1 {
		margin-right: .25rem!important
	}
	.mb-lg-1,
	.my-lg-1 {
		margin-bottom: .25rem!important
	}
	.ml-lg-1,
	.mx-lg-1 {
		margin-left: .25rem!important
	}
	.m-lg-2 {
		margin: .5rem!important
	}
	.mt-lg-2,
	.my-lg-2 {
		margin-top: .5rem!important
	}
	.mr-lg-2,
	.mx-lg-2 {
		margin-right: .5rem!important
	}
	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: .5rem!important
	}
	.ml-lg-2,
	.mx-lg-2 {
		margin-left: .5rem!important
	}
	.m-lg-3 {
		margin: 1rem!important
	}
	.mt-lg-3,
	.my-lg-3 {
		margin-top: 1rem!important
	}
	.mr-lg-3,
	.mx-lg-3 {
		margin-right: 1rem!important
	}
	.mb-lg-3,
	.my-lg-3 {
		margin-bottom: 1rem!important
	}
	.ml-lg-3,
	.mx-lg-3 {
		margin-left: 1rem!important
	}
	.m-lg-4 {
		margin: 1.5rem!important
	}
	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem!important
	}
	.mr-lg-4,
	.mx-lg-4 {
		margin-right: 1.5rem!important
	}
	.mb-lg-4,
	.my-lg-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-lg-4,
	.mx-lg-4 {
		margin-left: 1.5rem!important
	}
	.m-lg-5 {
		margin: 3rem!important
	}
	.mt-lg-5,
	.my-lg-5 {
		margin-top: 3rem!important
	}
	.mr-lg-5,
	.mx-lg-5 {
		margin-right: 3rem!important
	}
	.mb-lg-5,
	.my-lg-5 {
		margin-bottom: 3rem!important
	}
	.ml-lg-5,
	.mx-lg-5 {
		margin-left: 3rem!important
	}
	.p-lg-0 {
		padding: 0!important
	}
	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0!important
	}
	.pr-lg-0,
	.px-lg-0 {
		padding-right: 0!important
	}
	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0!important
	}
	.pl-lg-0,
	.px-lg-0 {
		padding-left: 0!important
	}
	.p-lg-1 {
		padding: .25rem!important
	}
	.pt-lg-1,
	.py-lg-1 {
		padding-top: .25rem!important
	}
	.pr-lg-1,
	.px-lg-1 {
		padding-right: .25rem!important
	}
	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: .25rem!important
	}
	.pl-lg-1,
	.px-lg-1 {
		padding-left: .25rem!important
	}
	.p-lg-2 {
		padding: .5rem!important
	}
	.pt-lg-2,
	.py-lg-2 {
		padding-top: .5rem!important
	}
	.pr-lg-2,
	.px-lg-2 {
		padding-right: .5rem!important
	}
	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: .5rem!important
	}
	.pl-lg-2,
	.px-lg-2 {
		padding-left: .5rem!important
	}
	.p-lg-3 {
		padding: 1rem!important
	}
	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem!important
	}
	.pr-lg-3,
	.px-lg-3 {
		padding-right: 1rem!important
	}
	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem!important
	}
	.pl-lg-3,
	.px-lg-3 {
		padding-left: 1rem!important
	}
	.p-lg-4 {
		padding: 1.5rem!important
	}
	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem!important
	}
	.pr-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem!important
	}
	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem!important
	}
	.p-lg-5 {
		padding: 3rem!important
	}
	.pt-lg-5,
	.py-lg-5 {
		padding-top: 3rem!important
	}
	.pr-lg-5,
	.px-lg-5 {
		padding-right: 3rem!important
	}
	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 3rem!important
	}
	.pl-lg-5,
	.px-lg-5 {
		padding-left: 3rem!important
	}
	.m-lg-auto {
		margin: auto!important
	}
	.mt-lg-auto,
	.my-lg-auto {
		margin-top: auto!important
	}
	.mr-lg-auto,
	.mx-lg-auto {
		margin-right: auto!important
	}
	.mb-lg-auto,
	.my-lg-auto {
		margin-bottom: auto!important
	}
	.ml-lg-auto,
	.mx-lg-auto {
		margin-left: auto!important
	}
}

@media(min-width:1200px) {
	.m-xl-0 {
		margin: 0!important
	}
	.mt-xl-0,
	.my-xl-0 {
		margin-top: 0!important
	}
	.mr-xl-0,
	.mx-xl-0 {
		margin-right: 0!important
	}
	.mb-xl-0,
	.my-xl-0 {
		margin-bottom: 0!important
	}
	.ml-xl-0,
	.mx-xl-0 {
		margin-left: 0!important
	}
	.m-xl-1 {
		margin: .25rem!important
	}
	.mt-xl-1,
	.my-xl-1 {
		margin-top: .25rem!important
	}
	.mr-xl-1,
	.mx-xl-1 {
		margin-right: .25rem!important
	}
	.mb-xl-1,
	.my-xl-1 {
		margin-bottom: .25rem!important
	}
	.ml-xl-1,
	.mx-xl-1 {
		margin-left: .25rem!important
	}
	.m-xl-2 {
		margin: .5rem!important
	}
	.mt-xl-2,
	.my-xl-2 {
		margin-top: .5rem!important
	}
	.mr-xl-2,
	.mx-xl-2 {
		margin-right: .5rem!important
	}
	.mb-xl-2,
	.my-xl-2 {
		margin-bottom: .5rem!important
	}
	.ml-xl-2,
	.mx-xl-2 {
		margin-left: .5rem!important
	}
	.m-xl-3 {
		margin: 1rem!important
	}
	.mt-xl-3,
	.my-xl-3 {
		margin-top: 1rem!important
	}
	.mr-xl-3,
	.mx-xl-3 {
		margin-right: 1rem!important
	}
	.mb-xl-3,
	.my-xl-3 {
		margin-bottom: 1rem!important
	}
	.ml-xl-3,
	.mx-xl-3 {
		margin-left: 1rem!important
	}
	.m-xl-4 {
		margin: 1.5rem!important
	}
	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem!important
	}
	.mr-xl-4,
	.mx-xl-4 {
		margin-right: 1.5rem!important
	}
	.mb-xl-4,
	.my-xl-4 {
		margin-bottom: 1.5rem!important
	}
	.ml-xl-4,
	.mx-xl-4 {
		margin-left: 1.5rem!important
	}
	.m-xl-5 {
		margin: 3rem!important
	}
	.mt-xl-5,
	.my-xl-5 {
		margin-top: 3rem!important
	}
	.mr-xl-5,
	.mx-xl-5 {
		margin-right: 3rem!important
	}
	.mb-xl-5,
	.my-xl-5 {
		margin-bottom: 3rem!important
	}
	.ml-xl-5,
	.mx-xl-5 {
		margin-left: 3rem!important
	}
	.p-xl-0 {
		padding: 0!important
	}
	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0!important
	}
	.pr-xl-0,
	.px-xl-0 {
		padding-right: 0!important
	}
	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0!important
	}
	.pl-xl-0,
	.px-xl-0 {
		padding-left: 0!important
	}
	.p-xl-1 {
		padding: .25rem!important
	}
	.pt-xl-1,
	.py-xl-1 {
		padding-top: .25rem!important
	}
	.pr-xl-1,
	.px-xl-1 {
		padding-right: .25rem!important
	}
	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: .25rem!important
	}
	.pl-xl-1,
	.px-xl-1 {
		padding-left: .25rem!important
	}
	.p-xl-2 {
		padding: .5rem!important
	}
	.pt-xl-2,
	.py-xl-2 {
		padding-top: .5rem!important
	}
	.pr-xl-2,
	.px-xl-2 {
		padding-right: .5rem!important
	}
	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: .5rem!important
	}
	.pl-xl-2,
	.px-xl-2 {
		padding-left: .5rem!important
	}
	.p-xl-3 {
		padding: 1rem!important
	}
	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem!important
	}
	.pr-xl-3,
	.px-xl-3 {
		padding-right: 1rem!important
	}
	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem!important
	}
	.pl-xl-3,
	.px-xl-3 {
		padding-left: 1rem!important
	}
	.p-xl-4 {
		padding: 1.5rem!important
	}
	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem!important
	}
	.pr-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem!important
	}
	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem!important
	}
	.pl-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem!important
	}
	.p-xl-5 {
		padding: 3rem!important
	}
	.pt-xl-5,
	.py-xl-5 {
		padding-top: 3rem!important
	}
	.pr-xl-5,
	.px-xl-5 {
		padding-right: 3rem!important
	}
	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 3rem!important
	}
	.pl-xl-5,
	.px-xl-5 {
		padding-left: 3rem!important
	}
	.m-xl-auto {
		margin: auto!important
	}
	.mt-xl-auto,
	.my-xl-auto {
		margin-top: auto!important
	}
	.mr-xl-auto,
	.mx-xl-auto {
		margin-right: auto!important
	}
	.mb-xl-auto,
	.my-xl-auto {
		margin-bottom: auto!important
	}
	.ml-xl-auto,
	.mx-xl-auto {
		margin-left: auto!important
	}
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.text-justify {
	text-align: justify!important
}

.text-nowrap {
	white-space: nowrap!important
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.text-left {
	text-align: left!important
}

.text-right {
	text-align: right!important
}

.text-center {
	text-align: center!important
}

@media(min-width:576px) {
	.text-sm-left {
		text-align: left!important
	}
	.text-sm-right {
		text-align: right!important
	}
	.text-sm-center {
		text-align: center!important
	}
}

@media(min-width:768px) {
	.text-md-left {
		text-align: left!important
	}
	.text-md-right {
		text-align: right!important
	}
	.text-md-center {
		text-align: center!important
	}
}

@media(min-width:992px) {
	.text-lg-left {
		text-align: left!important
	}
	.text-lg-right {
		text-align: right!important
	}
	.text-lg-center {
		text-align: center!important
	}
}

@media(min-width:1200px) {
	.text-xl-left {
		text-align: left!important
	}
	.text-xl-right {
		text-align: right!important
	}
	.text-xl-center {
		text-align: center!important
	}
}

.text-lowercase {
	text-transform: lowercase!important
}

.text-uppercase {
	text-transform: uppercase!important
}

.text-capitalize {
	text-transform: capitalize!important
}

.font-weight-light {
	font-weight: 300!important
}

.font-weight-normal {
	font-weight: 400!important
}

.font-weight-bold {
	font-weight: 500!important
}

.font-italic {
	font-style: italic!important
}

.text-white {
	color: #fff!important
}

.text-primary {
	color: #2798ea!important
}

a.text-primary:hover,
a.text-primary:focus {
	color: #147eca!important
}

.text-secondary {
	color: #7c858d!important
}

a.text-secondary:hover,
a.text-secondary:focus {
	color: #646b72!important
}

.text-success {
	color: #77d600!important
}

a.text-success:hover,
a.text-success:focus {
	color: #5ba300!important
}

.text-info {
	color: #33bCc3!important
}

a.text-info:hover,
a.text-info:focus {
	color: #28959b!important
}

.text-warning {
	color: #efb100!important
}

a.text-warning:hover,
a.text-warning:focus {
	color: #bc8b00!important
}

.text-danger {
	color: #FF8A80!important
}

a.text-danger:hover,
a.text-danger:focus {
	color: #ff5b4d!important
}

.text-light {
	color: #f8f9fa!important
}

a.text-light:hover,
a.text-light:focus {
	color: #dae0e5!important
}

.text-dark {
	color: #343a40!important
}

a.text-dark:hover,
a.text-dark:focus {
	color: #1d2124!important
}

.text-dietbox {
	color: #69C600!important
}

a.text-dietbox:hover,
a.text-dietbox:focus {
	color: #4e9300!important
}

.text-dietbox-secondary {
	color: #5C5091!important
}

a.text-dietbox-secondary:hover,
a.text-dietbox-secondary:focus {
	color: #473e70!important
}

.text-dietbox-app {
	color: #F2B705!important
}

a.text-dietbox-app:hover,
a.text-dietbox-app:focus {
	color: #c09104!important
}

.text-pro {
	color: #FD0!important
}

a.text-pro:hover,
a.text-pro:focus {
	color: #ccb100!important
}

.text-recebafacil {
	color: #5F7D8C!important
}

a.text-recebafacil:hover,
a.text-recebafacil:focus {
	color: #4a626e!important
}

.text-qpc {
	color: #d24297!important
}

a.text-qpc:hover,
a.text-qpc:focus {
	color: #b62b7d!important
}

.text-anamnese {
	color: #F46B59!important
}

a.text-anamnese:hover,
a.text-anamnese:focus {
	color: #f14029!important
}

.text-antropometria {
	color: #9149BD!important
}

a.text-antropometria:hover,
a.text-antropometria:focus {
	color: #75389b!important
}

.text-plano-alimentar {
	color: #60B738!important
}

a.text-plano-alimentar:hover,
a.text-plano-alimentar:focus {
	color: #4c902c!important
}

.text-plano-alimentar-light {
	color: #C9E3DB!important
}

a.text-plano-alimentar-light:hover,
a.text-plano-alimentar-light:focus {
	color: #a7d2c5!important
}

.text-recordatorio {
	color: #FAAF33!important
}

a.text-recordatorio:hover,
a.text-recordatorio:focus {
	color: #f49a06!important
}

.text-gasto-energetico {
	color: #26A6D5!important
}

a.text-gasto-energetico:hover,
a.text-gasto-energetico:focus {
	color: #1e84aa!important
}

.text-avaliacao-laboratorial {
	color: #33797F!important
}

a.text-avaliacao-laboratorial:hover,
a.text-avaliacao-laboratorial:focus {
	color: #24565b!important
}

.text-prescricao {
	color: #384C84!important
}

a.text-prescricao:hover,
a.text-prescricao:focus {
	color: #293760!important
}

.text-consulta {
	color: #2798ea!important
}

a.text-consulta:hover,
a.text-consulta:focus {
	color: #147eca!important
}

.text-retorno {
	color: #009788!important
}

a.text-retorno:hover,
a.text-retorno:focus {
	color: #00645a!important
}

.text-previsao-retorno {
	color: #81286D!important
}

a.text-previsao-retorno:hover,
a.text-previsao-retorno:focus {
	color: #5a1c4c!important
}

.text-aniversario {
	color: #E2A826!important
}

a.text-aniversario:hover,
a.text-aniversario:focus {
	color: #bc8a19!important
}

.text-lima {
	color: #EFFF33!important
}

a.text-lima:hover,
a.text-lima:focus {
	color: #ebff00!important
}

.text-azul-ceu {
	color: #5FC0DD!important
}

a.text-azul-ceu:hover,
a.text-azul-ceu:focus {
	color: #35afd4!important
}

.text-videoconferencia {
	color: #8132b1!important
}

a.text-videoconferencia:hover,
a.text-videoconferencia:focus {
	color: #642789!important
}

.text-body {
	color: #495057!important
}

.text-muted {
	color: #adb5bd!important
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5)!important
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5)!important
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0
}

.visible {
	visibility: visible!important
}

.invisible {
	visibility: hidden!important
}

@media print {
	*,
	*::before,
	*::after {
		text-shadow: none!important;
		box-shadow: none!important
	}
	a:not(.btn) {
		text-decoration: underline
	}
	abbr[title]::after {
		content: " (" attr(title) ")"
	}
	pre {
		white-space: pre-wrap!important
	}
	pre,
	blockquote {
		border: 1px solid #adb5bd;
		page-break-inside: avoid
	}
	thead {
		display: table-header-group
	}
	tr,
	img {
		page-break-inside: avoid
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3
	}
	h2,
	h3 {
		page-break-after: avoid
	}
	@page {
		size: a3
	}
	body {
		min-width: 992px!important
	}
	.container {
		min-width: 992px!important
	}
	.navbar {
		display: none
	}
	.badge {
		border: 1px solid #000
	}
	.table {
		border-collapse: collapse!important
	}
	.table td,
	.table th {
		background-color: #fff!important
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #dee2e6!important
	}
	.table-dark {
		color: inherit
	}
	.table-dark th,
	.table-dark td,
	.table-dark thead th,
	.table-dark tbody+tbody {
		border-color: #dee2e6
	}
	.table .thead-dark th {
		color: inherit;
		border-color: #dee2e6
	}
}