:root {
  --background: #f5f5f5;
  --orange: #ff4200;
  --text: #343434;
  --textButton: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: var(--background);
}

body,
input,
button {
  font: 14px "Roboto", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.rccs__card--front{
  width: 100% !important;
}

button {
  cursor: pointer;
  background: #7260aa;
  color: #fff;
}

.black-to-purple{
  filter: invert(40%) sepia(53%) saturate(497%) hue-rotate(214deg) brightness(89%) contrast(88%);
}

.card-logo {
  width: 130px;
  margin-top: 15px;
}

.card-logo-icon {
  width: 60px;
}

.mini-icon, .icon{
  text-align: inherit;
  width: 18px;
}

.mini-icon {
  margin-right: 8px;
  width: 18px;
}

.icon{
  width: 32px;
}

.card-body p {
  font-size: 15px;
  white-space: pre-wrap;
  line-height: 30px;
}

.card-body p span {
  font-weight: bold;
}

.card-shadow {
  margin: auto auto 15px;
}

.height-100vh {
  height: 90vh;
}

.f-13px {
  font-size: 13px !important;
}

.rccs__card--unknown > div {
  @media (max-width: 1199px) {
    width: 250px;
  }
}
