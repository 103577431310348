
.contentPix{
    display: flex;
    justify-content: space-evenly;
  
    @media (max-width: 800px) {
      display: block;
    }
  }
  .contentSubText{
    display: flex;
    align-items: center;
    padding: 0.7rem;
    justify-content: flex-start;
  }
  
  .containerPage{
    margin-top: 4rem;
  }
  
  .containerPayment{
    justify-content: center;
    text-align: center;
  }
  
  .contentAlert{
    margin-bottom: 1rem;
  }
  
  .containerDigital{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .contentButton{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .containerButton{
    display: flex;
    justify-content: center;
    align-items: center;
  
    @media (max-width: 800px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  
  .containerButtonLoading{
    display: grid;
    justify-content: center;
    align-items: center;
  
    @media (max-width: 800px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .contentMain{
    display: flex;
    justify-content: center;
    width: 70%;

    background-color: #ffffff;
    box-shadow: 0 0.25rem 0.75rem 0 white/10% ;
    padding: 1rem;
  
    @media (max-width: 1000px) {
      width: 90%;
    }
  }
  
  .withSpace{
    margin-top: 6rem;
  }
  
  .noSpace{
    margin-top: 1rem;
  }
  
  .contentResult{
    margin-bottom: 1rem;
  }
  
  .containerMain{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .contentIcon{
    font-size: xx-large;
    margin-right: 0.5rem;
  }
  
  .containerListWallets{
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  
    :hover{
     background-color: var(--background-light);
    }
  }
  
  .contentWallet{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.7rem;
    border: 1px solid var(--gray);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .ImageWallet{
    height: 1.7rem;
    margin-left: 0.7rem;
  }
  
  .contentQrCode{
    height: 10rem;
  }
  
  .contentIconClick{
    cursor: pointer;
    color: var(--default);
    font-size: 1.3rem;
    :hover{
      color: var(--gray);
    }
  }
  
  .containerIcon{
  
    align-items: center;
  
  }
  
  .main{
    margin-bottom: 0.5rem;
  }

  .colorGreen{
    color: #02b302;
    font-weight: 500;
  }
  .containerResult{
    display: grid;
    margin-right: 32px;
    justify-content: center;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 0.25rem 0.75rem 0 white/10% ;
  }

  .contentTextResult{
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  .contentIconResult{
    font-size: 2.5rem;
    color: white;
    padding: 1rem;
    border-radius: 50%;
    background-color: #02b302;

  }