.container-request-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;
  padding: 8px;
  width: 100%;
  border-radius: 4px;

  margin: 16px 0px 24px 0px;

  box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
}

.business {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  width: 95%;
}

.information {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 8px;
}

.information-total {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 8px;
}

.date {
  color: #adb5bd;
}
