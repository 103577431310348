.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spaceBottom{
  margin-bottom: 1rem;
}


.styleIdentity{
  width: 100%;
  height: 35.5px;
  padding-left: 0.5rem;
}

.containerName{
  display:flex; 
  justify-content:center; 
  align-items:center;
}
.contentPname{
  display:flex; 
  font-size:1.8rem;  
  font-weight:600;
  color:white;
  letter-spacing:0.2rem;
}

.contentSname{
  display:flex; 
  font-size:1.8rem;  
  font-weight:100;
  color:white;
  letter-spacing:0.3rem;
}

#PaymentForm input {
  border: 1px #dbdbdb solid;
}

.creditCard-m-button {
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: none;
  display: block;
  font-size: 13px;
  outline: none;
  padding: 7px;
  transition: all 0.4s;
  width: 100%;
}

.creditCard-group {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.left {
  flex: 2;
}

.margin{
  margin-top: 2rem !important;
}

.right {
  flex: 1;
  margin-left: 15px;
}

.ui .loader {
  color: #fff !important;
}

.button-payment {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7260aa;

  color: #fff;

  width: 100%;
  height: 32px;

  cursor: pointer;

  &:hover {
    background-color: #7260aaed;
    opacity: 0.9;
  }
}

.button-payment-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7260aa;
  color: #fff;
  width: 100%;
  height: 32px;

  cursor: no-drop !important;

  &:hover {
    background-color: #7260aa;
    opacity: 0.9;
  }
}
.containerForm{
  display: flex;
  justify-content: center;
  width: 100%;
}

.contentForm{
  width: 50%;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 70%;
  }
}

.message-error {
  color: #7260aa;
  margin: 16px;
}

.form-group-input {
  margin-top: 8px;
}

.request-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 64px;
}

.loading-spiner {
  margin-top: 25px;

  img {
    width: 100px;
    height: 100px;
    margin-left: -25px;
  }
}

.fieldsMessage{
  margin-bottom: 1.5rem;
}

.title{
  margin-bottom: 1rem;
}
