p {
  font-size: 15px;
}

a {
  font-weight: 700;
  color: #287559;
  text-decoration: none;
  font-size: 14px;
}

a:hover {
  color: #59bf99;
  opacity: 0.7;
  text-decoration: none;
}

.ui.message {
  border-radius: 4px;
}
