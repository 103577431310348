.containerName{
  display:flex; 
  justify-content:center; 
  align-items:center;
}
.link{
  color: #7260aa;
}
.contentPname{
  display:flex; 
  font-size:1.8rem;  
  font-weight:600;
  color:white;
  letter-spacing:0.2rem;
}

.contentSname{
  display:flex; 
  font-size:1.8rem;  
  font-weight:100;
  color:white;
  letter-spacing:0.3rem;
}

#loading {
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5000;
  background: #fff;
}

#loading .loading-inner {
  position: absolute;
  top: 40%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  -moz-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  -o-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-moz-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

@-moz-keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

.progress-loading .indeterminate:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress-loading .indeterminate:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

body {
  background-size: cover;
  font-size: 15px;
}

.btn-primary {
  color: #fff;
  background-color: #2798ea;
  border-color: #2798ea;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

h2 {
  margin: 30px auto;
  color: #777;
  font-size: 24px;
}

p {
  margin: 15px auto;
  color: #777;
  font-size: 18px;
}

label {
  margin-bottom: 0.2rem;
}

.form-control {
  font-size: 14px;
}

.input-text {
  font-size: 24px;
  height: 65px;
  border-radius: 10px;
  border: 2px solid #5bc0de;
  padding-left: 20px;
  text-align: center;
  margin: auto;
  margin-top: 14px;
}

.btn-submit {
  font-size: 24px;
  height: 70px;
  border-radius: 10px;
  line-height: 43px;
  border: 2px solid #5bc0de;
  background: #5bc0de;
  color: #fff;
  margin-top: 20px;
}

.btn-submit:hover,
.btn-submit:focus,
.btn-submit:active,
.btn-submit.active {
  color: white;
  background: #9bd8eb;
}

.img-logo {
  max-width: 100%;
  height: 80px;
}

.card-shadow {
  box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.1);
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: 0;
}

.btn-block {
  width: 88%;
  margin-left: 6%;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.btn-back {
  line-height: 0;
  float: left;
  margin-top: 2px;
  margin-right: 7px;
}

.validationMessage {
  padding: 5px;
  border-radius: 4px;
  color: #ea2727;
  font-size: 13px;
}

#particles-js {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 30.125rem;
  top: 0;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% calc(82%), 0 100%);
}

.particles-js-canvas-el {
  width: "100%";
  height: "100%";
}

.bg-gradient {
  background-image: -webkit-linear-gradient(
    90deg,
    #7260aa 0,
    #68a8a5 100%
  ) !important;
}

.container-login {
  display: table;
  table-layout: fixed;
  position: absolute;
  height: 100%;
  width: 100%;
}

.container-login .container {
  text-align: center;
}

h5,
.h5 {
  font-size: 18px;
}

@media (min-height: 980px) {
  .container-card {
    margin-bottom: 200px;
  }
}

@media (min-height: 1250px) {
  .container-card {
    margin-bottom: 300px;
  }
}

@media (min-height: 1400px) {
  .container-card {
    margin-bottom: 400px;
  }
}

@media (min-height: 1550px) {
  .container-card {
    margin-bottom: 600px;
  }
}
